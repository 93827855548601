import Router from "next/router";
import React from "react";

const formatterOptions = {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 2
};
const formatter = new Intl.NumberFormat("en-US", formatterOptions);
const formatterNoFraction = new Intl.NumberFormat("en-US", {
  ...formatterOptions,
  minimumFractionDigits: 0
});

const numberFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2
});

export const getSubLimitName = (subLimitId) => {
  const subLimits = {
    complimentaryTreatment: "Complementary treatment",
    behaviouralTreatment: "Behavioural treatment",
    diagnosticScans: "Diagnostic scans",
    dentalTreatment: "Dental treatment",
    deathFromAccidentOrIllness: "Death from accident or illness",
    theftOrStray: "Theft or stray",
    preExisting: "Pre-existing allowance"
  };
  return subLimits[subLimitId];
};

export const capitalise = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getClaimConditionName = (claimCondition) => {
  let name = "";
  if (claimCondition.condition && claimCondition.condition.name) {
    ({ name } = claimCondition.condition);
  }
  return name;
};

export const claimsHandlingErrorHandler = (error) => {
  if (error.message === "GraphQL error: claim assigned to someone else") {
    window.location.reload(true);
  }
};

export const cleanTrackObject = (input) => {
  if (typeof input === "undefined" || input === null) {
    return undefined;
  }
  if (typeof input !== "object" || Array.isArray(input)) {
    return input;
  }

  const output = { ...input };

  Object.entries(input).forEach(([key, value]) => {
    const cleanedValue = cleanTrackObject(value);
    if (typeof cleanedValue === "undefined" || cleanedValue === null) {
      delete output[key];
    } else {
      output[key] = cleanedValue;
    }
  });

  if (Object.keys(output).length === 0) {
    return undefined;
  }

  return output;
};

export const redirect = (res, path) => {
  if (res) {
    res.writeHead(302, {
      Location: path,
      // Add the content-type for SEO considerations
      "Content-Type": "text/html; charset=utf-8"
    });
    res.end();
    return {};
  }
  Router.replace(path);
  return {};
};

export const draggableItemTypes = {
  HIGHLIGHT: "highlight"
};

export const formatAsCurrency = (val, includeFraction = true) =>
  includeFraction ? formatter.format(val) : formatterNoFraction.format(val);

export const formatAsNumber = (val) => numberFormatter.format(val);

export const getAge = (age) => {
  if (age === 0) return `< 1 year`;
  if (age === 1) return `1 year`;
  return `${age} years`;
};

export const getAlpha = (color, alpha = 1) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  if (!result) return "transparent";
  return `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
    result[3],
    16
  )},${alpha})`;
};

export const roundNumber = (num, scale) => {
  if (!`${num}`.includes("e")) {
    return +`${Math.round(`${num}e+${scale}`)}e-${scale}`;
  }
  const arr = `${num}`.split("e");
  let sig = "";
  if (+arr[1] + scale > 0) {
    sig = "+";
  }
  return +`${Math.round(`${+arr[0]}e${sig}${+arr[1] + scale}`)}e-${scale}`;
};

export const calculateMonthlyQuote = (
  basePrice,
  excessAmount,
  excessLimits,
  medicalCoverage,
  coverageFactors
) => {
  const medicalCoverageRelativity = coverageFactors.find(
    (cf) => String(cf.key) === String(medicalCoverage)
  );
  const excessAmountRelativity = excessLimits.find(
    (ea) => String(ea.key) === String(excessAmount)
  );

  const cost =
    parseFloat(basePrice) *
    parseFloat(medicalCoverageRelativity.relativity) *
    parseFloat(excessAmountRelativity.relativity);
  return roundNumber(cost, 2);
};

export const padDigits = (number, digits) =>
  Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function debouncer(...args) {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const throttle = (fn, threshold) => {
  const newThreshold = threshold || 250;
  let last;
  let deferTimer;

  return function b() {
    const now = +new Date();
    if (last && now < last + newThreshold) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function a() {
        last = now;
        fn.apply(this);
      }, newThreshold);
    } else {
      last = now;
      fn.apply(this);
    }
  };
};

export const calculateDistanceToNextTier = (currentTier, currentPoints) => {
  let tierFound = false;
  let str = "";
  Object.keys(process.env.tiers).forEach((tier) => {
    if (tierFound === true && str === "") {
      const pointsNeeded = process.env.tiers[tier] - currentPoints;
      str = `${pointsNeeded} points to ${tier}`;
    }
    if (currentTier === tier) {
      tierFound = true;
    }
  });
  return str;
};

export const groupBy = (xs, key) =>
  xs.reduce((rv, x) => {
    const newRv = { ...rv };
    (newRv[x[key]] = newRv[x[key]] || []).push(x);
    return newRv;
  }, {});

export const addressToSentence = (addressObj) => {
  const requiredParts = [
    addressObj.line1,
    addressObj.line2,
    addressObj.line3,
    addressObj.postcode
  ];

  return requiredParts.filter((p) => p && p !== "").join(", ");
};

export const sortArrayOfArrays = (a, b) => {
  if (a[0] < b[0]) {
    return -1;
  }
  if (a[0] > b[0]) {
    return 1;
  }
  return 0;
};

export const bolden = (text, query) => {
  if (!text) {
    // richard willars
    return "";
  }
  const queryParts = query.trim().toLowerCase().split(" "); // richard 07749109007
  const t = queryParts.length;
  let matches = [];

  for (let i = 0; i < t; i += 1) {
    const tmpIndex = text.toLowerCase().indexOf(queryParts[i]);
    if (tmpIndex !== -1) {
      matches.push([tmpIndex, tmpIndex + queryParts[i].length]);
    }
  }

  if (matches.length === 0) {
    return text;
  }
  matches = matches.sort(sortArrayOfArrays);

  let currentPosition = 0;
  return (
    <span>
      {matches.map((match) => {
        const toReturn = (
          <>
            {text.substring(currentPosition, match[0])}
            <strong>{text.substring(match[0], match[1])}</strong>
          </>
        );
        currentPosition = match[1]; // eslint-disable-line prefer-destructuring
        return toReturn;
      })}

      {text.substring(currentPosition)}
    </span>
  );
};

export const isUUIDV4 = (uuid) => {
  let s = `${uuid}`;
  s = s.match(
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[4][0-9a-fA-F]{3}-[89ABab][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$"
  );
  if (s === null) return false;
  return true;
};

export const cleanAndFormatText = (text) => {
  // Remove only standalone "p", "br" and "nbsp" tags
  // Use regular expressions to match standalone tags

  let cleanText = text
    ?.replace(/(?<!\w)br(?!\w)/g, "")
    .replace(/(?<!\w)p(?!\w)/g, "")
    .replace(/(?<!\w)nbsp(?!\w)/g, "");

  // Remove any extra spaces created by the replacements
  cleanText = cleanText?.replace(/\s+/g, " ").trim();

  return cleanText;
};
