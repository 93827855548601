import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import AsyncCreatable from "react-select/lib/AsyncCreatable";
import Creatable from "react-select/lib/Creatable";
import css from "styled-jsx/css";
import { Styles } from "react-select/lib/styles";
import { InputActionTypes } from "react-select/lib/types";
import stylingConfig from "../styling/config";
import { ThemeContext } from "../lib/contexts";

const styles = css`
  .waggelSelect {
    position: relative;
  }
  .validationError {
    color: ${stylingConfig.colours.coralPink};
    font-size: 16px;
    display: block;
    margin-left: 14px;
    margin-top: 5px;
  }
  .waggelSelect :global(input) {
    font-family: WaggelNunito, Helvetica, Arial, sans-serif;
  }
  .icon {
    position: absolute;
    z-index: 5;
    left: 16px;
    top: 12px;
    pointer-events: none;
  }
`;
const alignToControl = (placement: "top" | "bottom") => {
  const placementToCSSProp = { bottom: "top", top: "bottom" };
  return placement ? placementToCSSProp[placement] : "bottom";
};

const DropdownIndicator = ({
  children = (
    <svg width="9" height="4.5" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M 0 0 L 9 0 L 4.5 4.5 Z"
        // stroke="#9AA3B7"
        // strokeWidth="3"
        fill="#9AA3B7"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  selectProps,
  options,
  innerProps
}: {
  children: React.ReactNode;
  selectProps: {
    menuIsOpen: boolean;
    isDisabled: boolean;
  };
  options: any;
  innerProps: any;
}) => {
  if (options.length === 0 || selectProps.isDisabled) {
    return null;
  }
  return (
    <div
      {...innerProps}
      style={{
        marginRight: "14px",
        // marginTop: "2px",
        color: stylingConfig.colours.blueyGreyTwo,
        transition: "all cubic-bezier(0.4, 0, 0.2, 1) 0.2s",
        transform: selectProps.menuIsOpen ? "rotateZ(180deg)" : "none",
        transformOrigin: "center 10.5px 0"
      }}
    >
      {children}
    </div>
  );
};

const customStyles: Styles = {
  // clearIndicator: () => ({}),
  container: () => ({
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    WebkitOverflowScrolling: "touch",
    position: "relative"
  }),
  control: (_baseStyle: any, state: any) => {
    let backgroundColor = stylingConfig.colours.paleGreyTwo;
    if (state.isDisabled || state.isFocused)
      backgroundColor = stylingConfig.colours.paleGrey;

    return {
      alignItems: "center",
      backgroundColor,
      borderRadius: "6px",
      borderWidth: 0,
      cursor: state.isDisabled ? "initial" : "pointer",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      minHeight: "40px",
      outline: "0 !important",
      position: "relative",
      transition: "all cubic-bezier(0.4, 0, 0.2, 1) 0.2s",
      width: "100%",
      opacity: state.isDisabled ? 0.5 : 1
    };
  },
  dropdownIndicator: () => ({
    content: '"a"',
    marginRight: "10px",
    color: stylingConfig.colours.blueyGreyTwo
  }),
  group: () => ({ content: '"i"' }),
  groupHeading: () => ({ content: '"c"' }),
  indicatorsContainer: () => ({ content: '"d"' }),
  indicatorSeparator: () => ({ content: '"e"' }),
  input: (_baseStyle: any, state: any) => ({
    visibility: state && state.isDisabled ? "hidden" : "visible"
  }),
  loadingIndicator: () => ({ display: "none" }),
  loadingMessage: () => ({ content: '"g"' }),
  menu: (_baseStyle: any, state: { placement: "top" | "bottom" }) => ({
    [alignToControl(state.placement)]: "100%",
    backgroundColor: stylingConfig.colours.paleGreyTwo,
    borderRadius: "0 0 6px 6px",
    position: "absolute",
    left: 0,
    padding: "0",
    width: "100%",
    zIndex: 200,
    overflow: "hidden"
  }),
  menuList: (_baseStyle: any, state: { maxHeight: any }) => ({
    maxHeight: state.maxHeight,
    overflowY: "auto",
    position: "relative", // required for offset[Height, Top] > keyboard scroll
    WebkitOverflowScrolling: "touch"
  }),
  multiValue: () => ({ content: '"j"' }),
  multiValueLabel: () => ({ content: '"k"' }),
  multiValueRemove: () => ({ content: '"L"' }),
  noOptionsMessage: () => ({ content: '"m"' }),
  option: (
    _baseStyle: any,
    state: { isSelected: any; isFocused: any; isDisabled: any }
  ) => {
    let backgroundColour = "transparent";
    if (state.isSelected) {
      backgroundColour = stylingConfig.colours.waterBlue;
    } else if (state.isFocused) {
      backgroundColour = stylingConfig.colours.ceruleanBlue;
    }

    let colour = "inherit";
    let cursor = "pointer";
    if (state.isSelected || state.isFocused) {
      colour = stylingConfig.colours.koda;
    } else if (state.isDisabled) {
      cursor = "initial";
      colour = stylingConfig.colours.steel;
    }

    return {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "24px",
      padding: `8px 12px`,
      backgroundColor: backgroundColour,
      color: colour,
      cursor,
      display: "block",
      width: "100%",
      userSelect: "none",
      WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",

      // provide some affordance on touch devices
      ":active": {
        backgroundColor: stylingConfig.colours.ceruleanBlue,
        color: stylingConfig.colours.koda
      }
    };
  },
  placeholder: () => ({
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 700,
    position: "absolute",
    left: "12px"
  }),
  singleValue: () => ({
    color: stylingConfig.colours.darkGreyBlue,
    maxWidth: `calc(100% - ${stylingConfig.grid.m}px - ${stylingConfig.grid.m}px)`,
    overflow: "hidden",
    position: "absolute",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 700
  }),
  valueContainer: () => ({
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    padding: `${stylingConfig.grid.xxs}px 12px`,
    WebkitOverflowScrolling: "touch",
    position: "relative"
  })
};

type StylesModifier = (previousStyles: any) => Styles;

const customStylesTextarea: StylesModifier = (previousStyles) => ({
  ...previousStyles,
  control: (_baseStyle: any, state: any) => ({
    ...previousStyles.control(_baseStyle, state),
    alignItems: "flex-start",
    borderRadius: "6px",
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    minHeight: "90px",
    outline: "0 !important",
    position: "relative",
    transition: "all cubic-bezier(0.4, 0, 0.2, 1) 0.2s",
    width: "100%",
    zIndex: 3
  }),

  singleValue: () => ({
    ...previousStyles.singleValue(),
    maxWidth: `calc(100% - 24px)`,
    height: "70px",
    overflow: "auto",
    position: "absolute",
    top: "10px",
    transform: "none",
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 700
  }),
  valueContainer: () => ({
    ...previousStyles.valueContainer()
  })
});

const customStylesDropUp: StylesModifier = (previousStyles) => ({
  ...previousStyles,
  menu: (baseStyle: any, state: any) => ({
    ...previousStyles.menu(baseStyle, state),
    borderRadius: "6px 6px 0 0",
    top: "10px",
    transform: "translateY(-100%)"
  }),
  option: (baseStyle: any, state: any) => ({
    ...previousStyles.option(baseStyle, state),
    ":first-child": {
      paddingTop: "8px"
    },
    ":last-child": {
      paddingBottom: "18px"
    }
  })
});

const customStylesSmall: StylesModifier = (previousStyles) => ({
  ...previousStyles,
  control: (_baseStyle: any, state: any) => {
    let backgroundColor = stylingConfig.colours.paleGreyTwo;
    if (state.isDisabled || state.isFocused)
      backgroundColor = stylingConfig.colours.paleGrey;

    return {
      alignItems: "center",
      backgroundColor,
      borderRadius: "6px",
      borderWidth: 0,
      cursor: "pointer",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      outline: "0 !important",
      position: "relative",
      transition: "all cubic-bezier(0.4, 0, 0.2, 1) 0.2s",
      width: "100%",
      zIndex: 3,
      opacity: state.isDisabled ? 0.5 : 1
    };
  },
  option: (_baseStyle: any, state: any) => {
    let backgroundColour = "transparent";
    if (state.isSelected) {
      backgroundColour = stylingConfig.colours.waterBlue;
    } else if (state.isFocused) {
      backgroundColour = stylingConfig.colours.ceruleanBlue;
    }

    let colour = "inherit";
    if (state.isSelected || state.isFocused) {
      colour = stylingConfig.colours.koda;
    }
    return {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "16px",
      padding: `5px 15px`,
      backgroundColor: backgroundColour,
      color: colour,
      cursor: "pointer",
      display: "block",
      width: "100%",
      userSelect: "none",
      WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",

      // provide some affordance on touch devices
      ":active": {
        backgroundColor: state.isSelected
          ? stylingConfig.colours.ceruleanBlue
          : stylingConfig.colours.paleGreyTwo
      },
      ":first-child": {
        paddingTop: "25px"
      },
      ":last-child": {
        paddingBottom: "10px"
      }
    };
  },
  valueContainer: () => ({
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    padding: `5px 0 5px 15px`,
    WebkitOverflowScrolling: "touch",
    position: "relative"
  }),
  singleValue: () => ({
    color: stylingConfig.colours.darkGreyBlue,
    overflow: "hidden",
    position: "absolute",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 700
  }),
  menu: (_baseStyle: any, state: any) => ({
    [alignToControl(state.placement)]: "100%",
    backgroundColor: stylingConfig.colours.paleGreyTwo,
    borderRadius: "0 0 6px 6px",
    position: "absolute",
    top: "18.5px",
    left: 0,
    padding: "0",
    width: "100%",
    zIndex: 200,
    overflow: "hidden"
  }),
  dropdownIndicator: () => ({
    content: '"a"',
    marginRight: "10px",
    marginTop: "5px",
    color: stylingConfig.colours.blueyGreyTwo
  }),
  input: () => ({
    fontSize: "14px"
  })
});

const customStylesCustomerService: StylesModifier = (previousStyles) => ({
  ...previousStyles,
  control: (baseStyle: any, state: any) => ({
    ...previousStyles.control(baseStyle, state),
    borderRadius: "6px 6px 0 0",
    borderBottom: `2px solid ${stylingConfig.colours.paleGrey}`,
    color: `${stylingConfig.colours.steel}`,
    backgroundColor: state.isDisabled
      ? stylingConfig.colours.koda
      : stylingConfig.colours.paleGreyTwo,
    opacity: 1
  }),
  menu: (baseStyle: any, state: any) => ({
    ...previousStyles.menu(baseStyle, state),
    zIndex: 6
  }),
  option: (baseStyle: any, state: any) => {
    let color = stylingConfig.colours.steel;
    if (state.isSelected || state.isFocused) {
      color = stylingConfig.colours.koda;
    }

    return {
      ...previousStyles.option(baseStyle, state),
      fontWeight: 400,
      color
    };
  },
  placeholder: () => ({
    ...previousStyles.placeholder(),
    fontWeight: 400
  }),
  singleValue: () => ({
    ...previousStyles.singleValue(),
    maxWidth: "100%",
    fontWeight: 400,
    color: stylingConfig.colours.steel
  })
});

const customStylesAnalytics: StylesModifier = (previousStyles) => ({
  ...previousStyles,
  control: (baseStyle: any, state: any) => {
    return {
      ...previousStyles.control(baseStyle, state),
      height: "42px",
      borderRadius:
        state.isFocused && !state.isDisabled && state.selectProps.menuIsOpen
          ? "20px 20px 0 0"
          : "20px",
      border:
        state.isFocused && !state.isDisabled
          ? `1px solid ${stylingConfig.colours.otherGrey}`
          : "1px solid transparent",
      color: `${stylingConfig.colours.steel}`,
      backgroundColor: state.isDisabled
        ? stylingConfig.colours.bluishLight
        : stylingConfig.colours.bluishBlack,
      opacity: 1
    };
  },
  menu: (baseStyle: any, state: any) => {
    return {
      ...previousStyles.menu(baseStyle, state),
      backgroundColor: stylingConfig.colours.bluishBlack,
      borderRadius: "0 0 20px 20px",
      // border: `1px solid ${stylingConfig.colours.otherGrey}`,
      borderTop: "none",
      display: state.selectProps.isDisabled ? "none" : "inherit",
      zIndex: 6
    };
  },
  option: (baseStyle: any, state: any) => {
    return {
      ...previousStyles.option(baseStyle, state),
      fontWeight: 400,
      color: stylingConfig.colours.koda,
      fontSize: "16px",
      ":active": {
        backgroundColor: state.data.isNone
          ? stylingConfig.colours.bluishBlack
          : stylingConfig.colours.bluishLight
      },
      backgroundColor:
        (state.isFocused || state.isSelected) && !state.data.isNone
          ? stylingConfig.colours.bluishLight
          : stylingConfig.colours.bluishBlack,
      borderBottom: `1px solid ${stylingConfig.colours.otherGrey}`,
      ":last-child": {
        marginBottom: "20px"
      },
      cursor: state.data.isNone ? "default" : "pointer",
      width: "300px"
    };
  },
  placeholder: () => ({
    ...previousStyles.placeholder(),
    color: stylingConfig.colours.otherGrey,
    fontSize: "16px",
    fontWeight: 400,
    left: "42px"
  }),
  multiValue: () => ({
    ...previousStyles.multiValue(),
    lineHeight: "17px",
    color: stylingConfig.colours.koda,
    fontSize: "16px",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    paddingRight: "5px"
  }),
  singleValue: () => ({
    ...previousStyles.singleValue(),
    color: stylingConfig.colours.koda,
    fontSize: "16px",
    fontWeight: 400
  }),
  input: () => ({
    ...previousStyles.input(),
    fontSize: "16px",
    color: stylingConfig.colours.koda
  }),
  valueContainer: () => ({
    ...previousStyles.valueContainer(),
    paddingLeft: "42px"
  })
});

const customStylesAnalyticsLight: StylesModifier = (previousStyles) => ({
  ...previousStyles,
  control: (baseStyle: any, state: any) => {
    return {
      ...previousStyles.control(baseStyle, state),
      backgroundColor: state.isDisabled
        ? stylingConfig.colours.bluishLight
        : stylingConfig.colours.bluishDark
    };
  },
  menu: (baseStyle: any, state: any) => {
    return {
      ...previousStyles.menu(baseStyle, state),
      backgroundColor: stylingConfig.colours.bluishDark
    };
  },
  option: (baseStyle: any, state: any) => {
    return {
      ...previousStyles.option(baseStyle, state),
      ":active": {
        backgroundColor: state.data.isNone
          ? stylingConfig.colours.bluishDark
          : stylingConfig.colours.bluishLight
      },
      backgroundColor:
        (state.isFocused || state.isSelected) && !state.data.isNone
          ? stylingConfig.colours.bluishLight
          : stylingConfig.colours.bluishDark
    };
  },
  placeholder: () => ({
    ...previousStyles.placeholder(),
    left: "16px"
  }),
  valueContainer: () => ({
    ...previousStyles.valueContainer(),
    paddingLeft: "16px"
  })
});

const customStylesAnalyticsHideIcon: StylesModifier = (previousStyles) => ({
  ...previousStyles,
  placeholder: () => ({
    ...previousStyles.placeholder(),
    left: "16px"
  }),
  valueContainer: () => ({
    ...previousStyles.valueContainer(),
    paddingLeft: "16px"
  })
});

const customStylesError: StylesModifier = (previousStyles) => ({
  ...previousStyles,
  control: (baseStyle: any, state: any) => ({
    ...previousStyles.control(baseStyle, state),
    border: `2px solid ${stylingConfig.colours.coralPink}`
  })
});

export interface Option {
  value: string | number;
  label: string;
}

const MySelect = ({
  name,
  handleChange = () => {},
  components = {},
  options = undefined,
  loadOptions = undefined,
  isClearable = false,
  isSearchable = false,
  cacheOptions = false,
  placeholder = "Select...",
  defaultOptions = false,
  value = "",
  title = "",
  error = undefined,
  touched = {
    label: false,
    value: false
  },
  disabled = false,
  small = false,
  onInputChange = () => {},
  onLoadOptionsQuery = () => {},
  onLoadOptionsResolve = () => {},
  creatable = false,
  dropUp = false,
  textarea = false,
  showDropdownIndicator = false,
  updateProp = "selectKey",
  theme,
  hideIcon = false,
  multiSelect = false
}: {
  name: string;
  handleChange?: (e: any) => void;
  components?: any;
  options?: Option[];
  loadOptions?: (query: string, callback: (results: Option[]) => void) => void;
  isClearable?: boolean;
  isSearchable?: boolean;
  cacheOptions?: boolean;
  placeholder?: string;
  defaultOptions?: boolean;
  value?: number | string | any;
  title?: string;
  error?: string | boolean;
  touched?: boolean | { label: boolean; value: boolean };
  disabled?: boolean;
  small?: boolean;
  onInputChange?: (newInput: string, action: InputActionTypes) => void;
  onLoadOptionsQuery?: (query: string) => void;
  onLoadOptionsResolve?: (query: string, results: Option[]) => void;
  creatable?: boolean;
  dropUp?: boolean;
  textarea?: boolean;
  showDropdownIndicator?: boolean;
  updateProp?: string;
  theme?: string;
  hideIcon?: boolean;
  multiSelect?: boolean;
}) => {
  const [stateValue, setStateValue] = useState<Option | null>(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    let newVal;
    let updateState = true;
    if (typeof value !== "object") {
      if (value !== "") {
        if (options) {
          newVal = options.find((op) => op.value === value);
        } else if (loadOptions) {
          updateState = false;
          loadOptions("", (results) => {
            setStateValue(
              results.find((op) => String(op.value) === String(value))
            );
          });
        }
      }
    } else {
      newVal = value;
    }
    if (updateState) {
      setStateValue(newVal || null);
    }
  }, [loadOptions, options, value]);

  /* eslint-disable jsx-a11y/label-has-for */
  let componentsToUse = {
    ...components,
    DropdownIndicator: null,
    LoadingMessage: () => null,
    NoOptionsMessage: () => null
  };
  let customStylesToUse = customStyles;
  if (showDropdownIndicator || small) {
    componentsToUse.DropdownIndicator = DropdownIndicator;
  }
  if (dropUp) {
    customStylesToUse = customStylesDropUp(customStylesToUse);
  }
  if (textarea) {
    customStylesToUse = customStylesTextarea(customStylesToUse);
  }
  if (small) {
    customStylesToUse = customStylesSmall(customStylesToUse);
    componentsToUse = components;
  }

  let themeToUse = useContext(ThemeContext);
  if (theme) {
    themeToUse = theme;
  }
  if (themeToUse === "customerService") {
    customStylesToUse = customStylesCustomerService(customStylesToUse);
  }
  if (themeToUse === "analytics" || themeToUse === "analyticsLight") {
    customStylesToUse = customStylesAnalytics(customStylesToUse);
  }
  if (themeToUse === "analyticsLight") {
    customStylesToUse = customStylesAnalyticsLight(customStylesToUse);
  }
  if (themeToUse === "analytics" && hideIcon) {
    customStylesToUse = customStylesAnalyticsHideIcon(customStylesToUse);
  }
  if ((typeof touched === "boolean" ? touched : touched.value) && error) {
    customStylesToUse = customStylesError(customStylesToUse);
  }
  /* eslint-disable no-return-assign */
  return (
    <div
      className={`waggelSelect ${stateValue ? "hasValue" : ""} ${
        (typeof touched === "boolean" ? touched : touched.value) && error
          ? "error"
          : ""
      }`}
    >
      {themeToUse === "analytics" && !hideIcon && (
        <div className="icon">
          <img
            src={`${process.env.staticUrl}/icon-search-analytics.svg`}
            alt="search"
          />
        </div>
      )}
      <label htmlFor={name}>{title}</label>
      {options &&
        (creatable ? (
          <Creatable
            key={updateProp}
            components={componentsToUse}
            defaultValue={stateValue}
            isClearable={isClearable}
            value={stateValue}
            isSearchable={isSearchable}
            name={name}
            id={name}
            inputProps={{ autoComplete: "off" }}
            options={disabled ? [] : options}
            onChange={(val) => {
              if (!disabled) {
                handleChange({
                  persist: () => {},
                  target: { name, value: val }
                });
              }
            }}
            placeholder={placeholder}
            defaultOptions={defaultOptions}
            styles={customStylesToUse}
            maxMenuHeight={280}
            isDisabled={disabled}
            inputValue={inputValue}
            onInputChange={(newInput, { action }) => {
              onInputChange(newInput, action);
              setInputValue(newInput);
            }}
            isMulti={multiSelect}
          />
        ) : (
          <Select
            key={updateProp}
            components={componentsToUse}
            defaultValue={stateValue}
            isClearable={isClearable}
            value={stateValue}
            isSearchable={isSearchable}
            name={name}
            id={name}
            inputProps={{ autoComplete: "off" }}
            options={disabled ? [] : options}
            onChange={(val) => {
              if (!disabled) {
                handleChange({
                  persist: () => {},
                  target: { name, value: val }
                });
              }
            }}
            placeholder={placeholder}
            defaultOptions={defaultOptions}
            styles={customStylesToUse}
            maxMenuHeight={280}
            isDisabled={disabled}
            inputValue={inputValue}
            onInputChange={(newInput, { action }) => {
              onInputChange(newInput, action);
              setInputValue(newInput);
            }}
            isMulti={multiSelect}
          />
        ))}

      {loadOptions &&
        (creatable ? (
          <AsyncCreatable
            key={updateProp}
            components={componentsToUse}
            defaultValue={stateValue}
            isClearable={isClearable}
            isSearchable={isSearchable}
            inputProps={{ autoComplete: "off" }}
            name={name}
            value={stateValue}
            onChange={(val) => {
              if (!disabled) {
                handleChange({
                  persist: () => {},
                  target: { name, value: val }
                });
              }
            }}
            loadOptions={(query, callback) => {
              onLoadOptionsQuery(query);
              loadOptions(query, (results) => {
                callback(results);
                onLoadOptionsResolve(query, results);
              });
            }}
            openMenuOnFocus
            cacheOptions={cacheOptions}
            placeholder={placeholder}
            defaultOptions={defaultOptions}
            styles={customStylesToUse}
            maxMenuHeight={280}
            isDisabled={disabled}
            isMulti={multiSelect}
          />
        ) : (
          <AsyncSelect
            key={updateProp}
            components={componentsToUse}
            defaultValue={stateValue}
            isClearable={isClearable}
            isSearchable={isSearchable}
            inputProps={{ autoComplete: "off" }}
            name={name}
            value={stateValue}
            onChange={(val) => {
              if (!disabled) {
                handleChange({
                  persist: () => {},
                  target: { name, value: val }
                });
              }
            }}
            loadOptions={(query, callback) => {
              onLoadOptionsQuery(query);
              loadOptions(query, (results) => {
                callback(results);
                onLoadOptionsResolve(query, results);
              });
            }}
            openMenuOnFocus
            cacheOptions={cacheOptions}
            placeholder={placeholder}
            defaultOptions={defaultOptions}
            styles={customStylesToUse}
            maxMenuHeight={280}
            isDisabled={disabled}
            isMulti={multiSelect}
          />
        ))}

      {(typeof touched === "boolean" ? touched : touched.value) &&
        error &&
        typeof error === "string" && (
          <span className="validationError">{error}</span>
        )}
      <style jsx>{styles}</style>
    </div>
  );
  /* eslint-enable no-return-assign */
  /* eslint-enable jsx-a11y/label-has-for */
};

export default MySelect;
