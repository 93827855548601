import React from "react";
import stylingConfig from "../styling/config";

const Row = ({
  children,
  alignItems = "center",
  justifyContent = "flex-start",
  wrap = false,
  noWrap = false,
  wrapOnTablet = true,
  wrapOnMobile = true,
  fullHeight = false,
  fullWidth = false,
  style
}: {
  children: React.ReactNode;
  alignItems?: "center" | "flex-start" | "flex-end" | "baseline" | "stretch";
  justifyContent?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  wrap?: boolean;
  noWrap?: boolean;
  wrapOnTablet?: boolean;
  wrapOnMobile?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  style?: any;
}) => (
  <div className="row" style={style}>
    {children}
    <style jsx>{`
      .row {
        display: flex;
        align-items: ${alignItems};
        justify-content: ${justifyContent};
        flex-wrap: ${wrap ? "wrap" : "no-wrap"};
        height: ${fullHeight ? "100%" : "initial"};
        width: ${fullWidth ? "100%" : "initial"};
      }
      @media screen and (max-width: ${stylingConfig.breakpoints.l}) {
        .row {
          flex-wrap: ${wrapOnTablet && !noWrap ? "wrap" : "no-wrap"};
        }
      }
      @media screen and (max-width: ${stylingConfig.breakpoints.s}) {
        .row {
          flex-wrap: ${wrapOnMobile && !noWrap ? "wrap" : "no-wrap"};
        }
      }
    `}</style>
  </div>
);

export default Row;
