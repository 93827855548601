import React, { useState } from "react";
import css from "styled-jsx/css";
import stylingConfig from "../../styling/config";

const styles = css`
  @keyframes ellipsis {
    to {
      width: 1em;
    }
  }

  .ellipsisLoader:after {
    position: absolute;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "…";
    width: 0px;
  }

  .button {
    border: 2px solid ${stylingConfig.colours.penny};
    outline: 0;
    background: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    box-sizing: border-box;
    height: 40px;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-left: 0;
    padding-top: 0;
    max-width: 375px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: WaggelBrother, Helvetica, Arial, sans-serif !important;
    font-weight: 700;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
    background-color: ${stylingConfig.colours.koda};
  }
  .button.dark {
    border: 2px solid ${stylingConfig.colours.koda};
    color: ${stylingConfig.colours.koda};
    background-color: transparent;
  }
  .button.short {
    max-width: 172px;
  }
  .button.theme-black {
    background: ${stylingConfig.colours.penny};
    color: ${stylingConfig.colours.koda};
  }

  /* flash focus states */
  .flashWrapper {
    height: 100%;
    width: 70px;
    position: absolute;
    top: 0;
    overflow: hidden;
  }
  .flashWrapper.left {
    left: -65px;
  }
  .flashWrapper.right {
    left: calc(100% + 65px);
    transition: left 0.3s;
  }
  .flash {
    position: absolute;
    top: -20px;
    height: calc(100% + 40px);
    background-color: ${stylingConfig.colours.waggelBlue};
    transform: rotate(20deg);
  }
  .flash.left {
    left: 10px;
    width: 24px;
  }
  .flash.right {
    left: 40px;
    width: 12px;
  }
  .button.focused:not(:active) .flashWrapper.left {
    transition: left 0.3s;
    left: 70%;
  }
  .button.focused:not(:active) .flashWrapper.right {
    transition: left 0s;
    transition-delay: 0.3s;
    left: 70%;
  }
  .button:hover {
    background-color: ${stylingConfig.colours.paleBlue};
  }
  .button.dark:hover {
    color: ${stylingConfig.colours.penny};
  }
  .button:active {
    background-color: ${stylingConfig.colours.waggelBlue};
  }

  .children {
    position: relative;
    white-space: nowrap;
    text-transform: uppercase;
  }

  /* disabled styling */
  .button.disabled {
    cursor: initial;
    background-color: ${stylingConfig.colours.neutral02};
    border: 2px solid ${stylingConfig.colours.disabled};
  }
  .button.disabled .children {
    color: ${stylingConfig.colours.disabled};
  }

  /* loading styling */
  .button.loading {
    background-color: transparent;
    cursor: initial;
    border: 2px solid ${stylingConfig.colours.disabled};
  }
  .button.dark.loading {
    color: ${stylingConfig.colours.koda};
  }
  .button.loading .flash {
    background-color: transparent;
  }

  /* theme styling */
`;

export type Button2dProps = {
  href?: string;
  ariaLabel?: string;
  loading?: boolean;
  onClick?: (
    e: any,
    setInternalLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  children: React.ReactNode;
  purpose?: "secondary";
  disabled?: boolean;
  testAttr?: string;
  theme?: "" | "black";
  short?: boolean;
  dark?: boolean; // on a dark background
};

const Button2d = ({
  href,
  ariaLabel = "",
  testAttr = "",
  loading = false,
  onClick = () => {},
  children,
  purpose = "secondary",
  disabled = false,
  theme = "",
  short = false,
  dark = false
}: Button2dProps) => {
  const [internalLoading, setInternalLoading] = useState(false);
  const [focused, setFocused] = useState(false);

  const actuallyLoading = loading || internalLoading;

  const button = (
    <button
      aria-label={ariaLabel}
      data-test={testAttr}
      disabled={disabled}
      onClick={
        actuallyLoading || disabled
          ? (e) => {
              e.preventDefault();
            }
          : (e) => onClick(e, setInternalLoading)
      }
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      className={`button ${dark ? "dark" : ""} ${short ? "short" : ""} ${
        focused ? "focused" : ""
      } ${disabled ? "disabled" : ""} theme-${theme} ${purpose} ${
        actuallyLoading ? "loading" : ""
      }`}
      type="button"
    >
      <div className="flashWrapper left">
        <div className="flash left" />
        <div className="flash right" />
      </div>
      <div className="flashWrapper right">
        <div className="flash left" />
        <div className="flash right" />
      </div>
      <span className="children">
        {actuallyLoading ? (
          <span className="ellipsisLoader">LOADING</span>
        ) : (
          <span>{children}</span>
        )}
      </span>

      <style jsx>{styles}</style>
    </button>
  );

  if (href) {
    return (
      <a
        href={href}
        onClick={onClick as any}
        style={{ textDecoration: "none" }}
      >
        {button}
      </a>
    );
  }
  return button;
};

export default Button2d;
