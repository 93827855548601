import uuid from "uuid";
import { useEffect } from "react";
import { Cookies } from "react-cookie";
import { isUUIDV4 } from "./utils";

export const useCommonCookies = (
  cookies: Cookies
): {
  sessionId: string;
  deviceId: string;
} => {
  const allCookies = cookies.getAll();
  let deviceId = uuid.v4();
  if (allCookies.deviceId && isUUIDV4(allCookies.deviceId)) {
    ({ deviceId } = allCookies);
  } else if (allCookies.uId && isUUIDV4(allCookies.uId)) {
    deviceId = allCookies.uId;
  }

  let sessionId = uuid.v4();
  if (allCookies.sessionId && isUUIDV4(allCookies.sessionId)) {
    ({ sessionId } = allCookies);
  } else if (allCookies.sId && isUUIDV4(allCookies.sId)) {
    sessionId = allCookies.sId;
  }

  useEffect(() => {
    const d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    cookies.set("deviceId", deviceId, {
      path: "/",
      expires: d
    });
    cookies.set("sessionId", sessionId, {
      path: "/"
    });
  }, [deviceId, sessionId, cookies]);

  return {
    deviceId,
    sessionId
  };
};

export default {};
