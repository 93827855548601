interface Permissions {
  [key: string]: {
    value: number;
    group: number;
    category: string;
  };
}

// tslint:disable: object-literal-sort-keys
export const permissions: Permissions = {
  getSelf: {
    category: "users",
    group: 1,
    value: 1
  },
  viewOthersClaims: {
    category: "claims",
    group: 1,
    value: 2
  },
  processOthersClaims: {
    category: "claims",
    group: 1,
    value: 4
  },
  viewOthersPets: {
    category: "pets",
    group: 1,
    value: 8
  },
  viewOtherUsers: {
    category: "users",
    group: 1,
    value: 16
  },
  viewOthersPolicies: {
    category: "policies",
    group: 1,
    value: 32
  },
  viewOwnPets: {
    category: "pets",
    group: 1,
    value: 64
  },
  viewOtherVets: {
    category: "vets",
    group: 1,
    value: 128
  },
  viewOwnPolicies: {
    category: "policies",
    group: 1,
    value: 256
  },
  viewOwnClaims: {
    category: "claims",
    group: 1,
    value: 512
  },
  generateEmailTemplates: {
    category: "emails",
    group: 1,
    value: 1024
  },
  editOthersPolicies: {
    category: "policies",
    group: 1,
    value: 2048
  },
  editOtherUsers: {
    category: "users",
    group: 1,
    value: 4096
  },
  editSelf: {
    category: "users",
    group: 1,
    value: 8192
  },
  viewOwnActivities: {
    category: "activities",
    group: 1,
    value: 16384
  },
  viewOthersActivities: {
    category: "activities",
    group: 1,
    value: 32768
  },
  completeActivity: {
    category: "activities",
    group: 1,
    value: 65536
  },
  deleteOthersQuotes: {
    category: "policies",
    group: 1,
    value: 131072
  },
  createBlogPost: {
    category: "content",
    group: 1,
    value: 262144
  },
  editBlogPost: {
    category: "content",
    group: 1,
    value: 524288
  },
  deleteBlogPost: {
    category: "content",
    group: 1,
    value: 1048576
  },
  publishBlogPost: {
    category: "content",
    group: 1,
    value: 2097152
  },
  viewBlogStats: {
    category: "content",
    group: 1,
    value: 4194304
  },
  queryAnalytics: {
    category: "analytics",
    group: 1,
    value: 8388608
  },
  updateOwnPets: {
    category: "pets",
    group: 1,
    value: 16777216
  },
  updateOthersPets: {
    category: "pets",
    group: 1,
    value: 33554432
  },
  manageAffiliates: {
    category: "affiliates",
    group: 1,
    value: 67108864
  },
  manageAffiliateCampaigns: {
    category: "affiliates",
    group: 1,
    value: 134217728
  },
  sendNotifications: {
    category: "notifications",
    group: 1,
    value: 268435456
  },
  viewSegments: {
    category: "segments",
    group: 2,
    value: 1
  },
  viewNotifications: {
    category: "notifications",
    group: 2,
    value: 2
  },
  createContent: {
    category: "content",
    group: 2,
    value: 4
  },
  createBilling: {
    category: "billing",
    group: 2,
    value: 8
  },
  createOthersSupport: {
    category: "support",
    group: 2,
    value: 16
  },
  createOwnSupport: {
    category: "support",
    group: 2,
    value: 32
  },
  manageFiles: {
    category: "files",
    group: 2,
    value: 64
  },
  updateVets: {
    category: "vets",
    group: 2,
    value: 128
  },
  viewOwnFiles: {
    category: "files",
    group: 2,
    value: 256
  },
  viewOtherFiles: {
    category: "files",
    group: 2,
    value: 512
  },
  editOwnFiles: {
    category: "files",
    group: 2,
    value: 1024
  },
  editOtherFiles: {
    category: "files",
    group: 2,
    value: 2048
  },
  manageOwnAffiliate: {
    category: "affiliates",
    group: 2,
    value: 4096
  },
  viewOtherLimitedProfilePolicies: {
    category: "policies",
    group: 2,
    value: 8192
  },
  viewOtherLimitedProfilePets: {
    category: "pets",
    group: 2,
    value: 16384
  },
  viewOtherLimitedProfileUsers: {
    category: "users",
    group: 2,
    value: 32768
  },
  manageBlacklist: {
    category: "permissions",
    group: 2,
    value: 65536
  },
  editPermissions: {
    category: "permissions",
    group: 2,
    value: 131072
  },
  viewMagazine: {
    category: "magazine",
    group: 2,
    value: 262144
  },
  editMagazine: {
    category: "magazine",
    group: 2,
    value: 524288
  },
  approveLargeClaims: {
    category: "claims",
    group: 2,
    value: 268435456
  },
  approveVeryLargeAndThirdPartyClaims: {
    category: "claims",
    group: 2,
    value: 536870912
  }
};
// tslint:enable: object-literal-sort-keys
export default undefined;
