import React, { useEffect } from "react";
import Pusher from "pusher-js";
import { useApolloClient, gql, ApolloClient } from "@apollo/client";
import { useCookies } from "react-cookie";
import {
  GetPusherAuthQuery,
  GetPusherAuthQueryVariables
} from "../types/graphql";

let initialised = false;

const pusherAuthQuery = gql`
  query getPusherAuth(
    $socketId: String!
    $channelName: String!
    $deviceId: String!
  ) {
    getPusherAuth(
      socketId: $socketId
      channelName: $channelName
      deviceId: $deviceId
    ) {
      token
      channelData
    }
  }
`;

interface CallBacks {
  newTicket: (data: any) => any;
  replyToTicket: (data: any) => any;
  typing: (data: any) => any;
  notTyping: (data: any) => any;
  closedTicket: (data: any) => any;
  reopenedTicket: (data: any) => any;
  online: (data: any) => any;
  offline: (data: any) => any;
  incomingCall: (data: any) => any;
  callUnavailable: (data: any) => any;
  callEnded: (data: any) => any;
  callAnswered: (data: any) => any;
}

let channel: any;

const initialise = (
  userId: string,
  deviceId: string,
  client: ApolloClient<object>
) => {
  if (initialised === false && deviceId) {
    initialised = true;
    if (process.browser) {
      // Pusher.logToConsole = true;
      const pusher = new Pusher(process.env.pusher_appId, {
        authEndpoint: process.env.pusher_authUrl,
        cluster: "eu",
        forceTLS: true,
        authorizer: (initialChannel, options) => {
          return {
            authorize: async (socketId, callback) => {
              const { errors, data } = await client.query<
                GetPusherAuthQuery,
                GetPusherAuthQueryVariables
              >({
                query: pusherAuthQuery,
                variables: {
                  socketId,
                  channelName: initialChannel.name,
                  deviceId
                }
              });
              if (data) {
                callback(false, {
                  auth: data.getPusherAuth.token,
                  channel_data: data.getPusherAuth.channelData
                });
              } else if (errors) {
                callback(true, null);
              }
            }
          };
        }
      });
      const channelId = userId ? `userId-${userId}` : `deviceId-${deviceId}`;
      pusher.subscribe(`presence-${channelId}`);
      channel = pusher.subscribe(`private-${channelId}`);
      channel.bind("pusher:subscription_succeeded", () => {});
    }
  }
};

export const usePusher = (userId: string | undefined, callBacks: CallBacks) => {
  const [cookies] = useCookies(["deviceId"]);
  const client = useApolloClient();
  // console.log("channel", channel);
  useEffect(() => {
    initialise(userId, cookies.deviceId, client);
  }, [client, cookies.deviceId, userId]);

  useEffect(() => {
    if (channel) {
      // console.log("BIND CALLBACKS");
      // triggered when there is a new ticket
      channel.bind("NEW_TICKET", callBacks.newTicket);

      // triggered when there is a new reply to a ticket
      channel.bind("REPLY_TO_TICKET", callBacks.replyToTicket);

      // triggered when the user is typing
      channel.bind("TYPING", callBacks.typing);

      // triggered when the user has stopped typing
      channel.bind("NOT_TYPING", callBacks.notTyping);

      // triggered when a ticket is closed
      channel.bind("CLOSED_TICKET", callBacks.closedTicket);

      // triggered when a ticket is reopened
      channel.bind("REOPENED_TICKET", callBacks.reopenedTicket);

      // triggered when the user has come online. Should update the UI accordingly
      channel.bind("ONLINE", callBacks.online);

      // triggered when the user has gone offline. Should update the UI accordingly.
      channel.bind("OFFLINE", callBacks.offline);

      // triggered when a new call is available (should show incoming call UI if current user is not on a call)
      channel.bind("INCOMING_CALL", callBacks.incomingCall);

      // triggered when a call has left the queue (should hide incoming call UI)
      channel.bind("CALL_UNAVAILABLE", callBacks.callUnavailable);

      // triggered when a call has ended. Should hide incoming call UI and 'in call' UI
      channel.bind("CALL_ENDED", callBacks.callEnded);

      // triggered when a call is answered by customer services.
      // If answered by current user, set the state as in call and show relevant ui
      // If not answered by current user, hide incoming call UI
      channel.bind("CALL_ANSWERED", callBacks.callAnswered);

      return () => {
        channel.unbind("NEW_TICKET");
        channel.unbind("REPLY_TO_TICKET");
        channel.unbind("TYPING");
        channel.unbind("NOT_TYPING");
        channel.unbind("CLOSED_TICKET");
        channel.unbind("REOPENED_TICKET");
        channel.unbind("ONLINE");
        channel.unbind("OFFLINE");
        channel.unbind("INCOMING_CALL");
        channel.unbind("CALL_UNAVAILABLE");
        channel.unbind("CALL_ENDED");
        channel.unbind("CALL_ANSWERED");
      };
    }
    return () => {};
  }, [callBacks]);
};
export default () => {};
