/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useMemo } from "react";
import css from "styled-jsx/css";
import { gql, useMutation, useQuery } from "@apollo/client";

import Modal from "./modal";
import Input from "./input";
import Button from "./button";
import Select from "./select";
import Row from "./row";
import {
  CreateIntentMutation,
  CreateIntentMutationVariables,
  GetSupportIntentsQuery,
  SupportTicketReply,
  TrainIntentOnSupportTicketReplyMutation,
  TrainIntentOnSupportTicketReplyMutationVariables
} from "../types/graphql";

const styles = css`
  .wrapper {
    margin: 40px 20px 20px;
    white-space: pre-wrap;
  }
  .text,
  .input {
    margin-bottom: 10px;
  }

  .row {
    display: flex;
    width: 100%;
  }
  .row .button {
    flex-grow: 1;
  }
  .row .button:not(:last-child) {
    margin-right: 10px;
  }
  .row .button:not(:first-child) {
    margin-left: 10px;
  }
`;

export const GET_INTENTS = gql`
  query getSupportIntents {
    getSupportIntents {
      id
      name
      message
    }
  }
`;

export const CREATE_INTENT = gql`
  mutation createIntent($intentName: String!, $message: String!) {
    createIntent(intentName: $intentName, message: $message) {
      success
    }
  }
`;

export const TRAIN_INTENT = gql`
  mutation trainIntentOnSupportTicketReply(
    $ticketReplyId: Int!
    $text: String!
    $intentId: String
  ) {
    trainIntentOnSupportTicketReply(
      ticketReplyId: $ticketReplyId
      text: $text
      intentId: $intentId
    ) {
      id
      ticketId
      medium
      text
      isCustomer
      addedAt
      hidden
      userId
      user {
        id
        firstName
      }
      intentId
      intentConfidence
      intentTrained
    }
  }
`;

// const GET_PET_NAMES = gql`
//   query getPetNames($query: String!) {
//     analytics(query: $query) {
//       labels
//     }
//   }
// `;

const removeStopwords = (tokens, stopwords) => {
  return tokens.filter((value) => {
    return stopwords.indexOf(value.toLowerCase()) === -1;
  });
};

const ChatTrainingModal = ({
  showModal,
  setShowModal,
  reply
}: {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  reply: SupportTicketReply;
}) => {
  const getIntentsQuery = useQuery<GetSupportIntentsQuery>(GET_INTENTS);
  const intents = getIntentsQuery.data
    ? [...getIntentsQuery.data.getSupportIntents].filter(
        (i) => i.name !== "Default fallback"
      )
    : [];

  const [cleanedReplyText, setCleanedReplyText] = useState(reply.text);
  const [replyText, setReplyText] = useState(reply.text);

  useEffect(() => {
    if (cleanedReplyText) {
      const petNames = [];
      const garbageStrings = [
        "hi",
        "hello",
        "morning",
        "hey",
        "afternoon",
        "evening",
        "please",
        "1st",
        "2nd",
        "3rd",
        "4th",
        "5th",
        "6th",
        "7th",
        "8th",
        "9th",
        "10th",
        "11th",
        "12th",
        "13th",
        "14th",
        "15th",
        "16th",
        "17th",
        "18th",
        "19th",
        "20th",
        "21st",
        "22nd",
        "23rd",
        "24th",
        "25th",
        "26th",
        "27th",
        "28th",
        "29th",
        "30th",
        "31st",
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "jul",
        "aug",
        "sept",
        "oct",
        "nov",
        "dec",
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        ...petNames
      ];
      let ignoreList = [];
      garbageStrings.forEach((garbageString) => {
        ignoreList.push(garbageString);
        ignoreList.push(`${garbageString},`);
        ignoreList.push(`${garbageString}?`);
        ignoreList.push(`${garbageString}!`);
        ignoreList.push(`${garbageString}.`);
        ignoreList.push(`${garbageString}'s`);
      });

      ignoreList = ignoreList.map((item) => item.toLowerCase());

      setReplyText(
        removeStopwords(cleanedReplyText.split(" "), ignoreList).join(" ")
      );
    }
  }, [cleanedReplyText]);

  const replyIntent =
    getIntentsQuery.data &&
    getIntentsQuery.data.getSupportIntents.find((i) => i.id === reply.intentId);

  const [intentId, setIntentId] = useState(reply.intentId);

  useEffect(() => {
    if (replyIntent && replyIntent.name === "Default fallback") {
      setIntentId(undefined);
    }
  }, [replyIntent]);

  const selectedIntent = intents && intents.find((i) => i.id === intentId);

  const [showCreateModal, setShowCreateModal] = useState(false);

  const [newIntent, setNewIntent] = useState({
    name: "",
    message: ""
  });

  const newIntentNameError = !!intents.find((i) => i.name === newIntent.name);

  const [createIntentMutation] = useMutation<
    CreateIntentMutation,
    CreateIntentMutationVariables
  >(CREATE_INTENT, {
    refetchQueries: ["getSupportIntents"]
  });

  const [trainIntentMutation] =
    useMutation<
      TrainIntentOnSupportTicketReplyMutation,
      TrainIntentOnSupportTicketReplyMutationVariables
    >(TRAIN_INTENT);

  return (
    <div>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        width={600}
      >
        <div className="wrapper">
          <div>
            <h1>Train support chat</h1>
            <div className="reply">
              <div className="text">
                Original message: <strong>“{reply.text}”</strong>
              </div>
              <div className="text">Message to use for training:</div>
              <div className="input">
                <Input
                  name="replyText"
                  value={replyText}
                  handleChange={(e) => {
                    setReplyText(e.target.value);
                  }}
                  error={!replyText ? "Training message required" : ""}
                  touched
                />
              </div>
            </div>
            <div className="intents">
              <div className="text">
                <Row justifyContent="space-between">
                  <div>Select correct response:</div>
                  <Button
                    onClick={() => {
                      setShowModal(false);
                      setShowCreateModal(true);
                    }}
                  >
                    Create new response
                  </Button>
                </Row>
              </div>
              <div className="input">
                <Select
                  key="intentId"
                  name="intentId"
                  isClearable={false}
                  isSearchable
                  cacheOptions={false}
                  options={intents.map((i) => ({ value: i.id, label: i.name }))}
                  value={intentId}
                  handleChange={(e) => setIntentId(e.target.value.value)}
                />
              </div>
              <div className="text">
                <strong>
                  “
                  {selectedIntent &&
                    selectedIntent.message &&
                    selectedIntent.message.replace(/--/g, "\n")}
                  ”
                </strong>
              </div>
            </div>
            <div className="row">
              <div className="button">
                <Button
                  fullWidth
                  theme="green"
                  disabled={!intentId || !replyText}
                  onClick={(_e, setLoading) => {
                    if (intentId && replyText) {
                      setLoading(true);
                      trainIntentMutation({
                        variables: {
                          ticketReplyId: parseInt(reply.id, 10),
                          text: replyText,
                          intentId
                        }
                      }).then(() => {
                        setShowModal(false);
                        setLoading(false);
                      });
                    }
                  }}
                >
                  Submit
                </Button>
              </div>
              <div className="button">
                <Button
                  fullWidth
                  theme="red"
                  onClick={(_e, setLoading) => {
                    if (intentId && replyText) {
                      setLoading(true);
                      trainIntentMutation({
                        variables: {
                          ticketReplyId: parseInt(reply.id, 10),
                          text: replyText
                        }
                      }).then(() => {
                        setShowModal(false);
                        setLoading(false);
                      });
                    }
                  }}
                >
                  Skip training
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
          setShowModal(true);
        }}
        width={600}
      >
        <div className="wrapper">
          <h1>Create new response</h1>
          <div className="input">
            <Input
              label="Subject"
              name="intentName"
              value={newIntent.name}
              handleChange={(e) => {
                setNewIntent({ ...newIntent, name: e.target.value });
              }}
              error={
                newIntentNameError ? "Intent with this name already exists" : ""
              }
              touched={newIntentNameError}
            />
          </div>
          <div className="input">
            <Input
              label="Message to show customer"
              name="intentMessage"
              type="textarea"
              value={newIntent.message}
              handleChange={(e) => {
                setNewIntent({ ...newIntent, message: e.target.value });
              }}
            />
          </div>
          <div className="button">
            <Button
              fullWidth
              disabled={
                !newIntent.name || !newIntent.message || newIntentNameError
              }
              onClick={(_e, setLoading) => {
                if (
                  newIntent.name &&
                  newIntent.message &&
                  !newIntentNameError
                ) {
                  setLoading(true);
                  createIntentMutation({
                    variables: {
                      intentName: newIntent.name,
                      message: newIntent.message.replace(/\n/g, "--")
                    }
                  }).then(() => {
                    getIntentsQuery.refetch();
                    setShowCreateModal(false);
                    setShowModal(true);
                    setLoading(false);
                    setNewIntent({ name: "", message: "" });
                  });
                }
              }}
            >
              Create
            </Button>
          </div>
        </div>
      </Modal>
      <style jsx>{styles}</style>
    </div>
  );
};

export default ChatTrainingModal;
