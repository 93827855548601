import Router from "next/router";
import jwt from "jsonwebtoken";

export interface Auth {
  userId?: string;
  hasAccessToken: boolean;
}

export const decodeJwt = (accessToken: string) => {
  const decoded: any = jwt.decode(accessToken);
  return decoded.data;
};

export const getClientSideToken = (authToken?: string): Auth => {
  let body = { hasAccessToken: false };
  if (authToken) {
    try {
      body = {
        ...(jwt.decode(authToken) as any).data,
        hasAccessToken: !!authToken
      };
    } catch (parseError) {
      if (parseError.message === "jwt expired") {
        body = {
          hasAccessToken: true
        };
      }
    }
  }
  return body;
};

export const useAuthenticatedPages = (
  auth: Auth,
  asPath: string,
  redirectToLoginIfNotAuthenticated: boolean = true,
  redirectBackAfterLogin: boolean = true
) => {
  if (
    redirectToLoginIfNotAuthenticated &&
    !auth.hasAccessToken &&
    !asPath.startsWith("/login") &&
    process.browser
  ) {
    if (redirectBackAfterLogin) {
      if (process.browser) {
        Router.replace(`/login?next=${asPath}`);
      }
    }
    if (process.browser) {
      Router.replace("/login");
    }
  }
};
