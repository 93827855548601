/* eslint-disable no-console */
/* eslint-disable func-names */
import { useEffect, useCallback, useState } from "react";
import { useApolloClient, gql, ApolloClient } from "@apollo/client";
import windowEvent from "./subPub";
import { GetTwilioAccessTokenQuery } from "../types/graphql";

let initialised = false;

interface AnswerCall {
  (): Promise<void>;
}

interface EndCall {
  (): Promise<void>;
}

interface ToggleMuteCall {
  (): Promise<void>;
}

interface MakeCall {
  (phoneNumber: string): Promise<void>;
}

interface SendDigit {
  (digit: string): Promise<void>;
}

const twilioAuthQuery = gql`
  query getTwilioAccessToken {
    getTwilioAccessToken {
      id
    }
  }
`;

let device: any;
let goodToGo = false;
let muted = false;
let currentCall: any;

const getAuthToken = async (
  appClient: ApolloClient<object>
): Promise<string> => {
  const { errors, data } = await appClient.query<GetTwilioAccessTokenQuery>({
    query: twilioAuthQuery,
    fetchPolicy: "no-cache"
  });
  if (data && data.getTwilioAccessToken && data.getTwilioAccessToken.id) {
    return data.getTwilioAccessToken.id;
  }
  if (errors) {
    return "";
  }
  return "";
};

let apolloClient;
const initialise = async (appClient: ApolloClient<object>) => {
  apolloClient = appClient;
  if (initialised === false) {
    initialised = true;
    if (process.browser) {
      const token = await getAuthToken(appClient);
      if ((window as any).Twilio) {
        device = new (window as any).Twilio.Device();
        device = (window as any).Twilio.Device.setup(token);

        device.on("ready", function () {
          goodToGo = true;
          console.log("Twilio.Device Ready!");
        });

        device.on("offline", function () {
          goodToGo = false;
          console.log("Twilio.Device offline!");
        });

        device.on("online", function () {
          goodToGo = true;
          console.log("Twilio.Device online!");
        });

        device.on("disconnect", function () {
          console.log("disconnect");
          currentCall = undefined;
        });

        device.on("error", async function (error) {
          goodToGo = false;
          console.log(`Twilio.Device Error: ${error.message}`);
          if (error.message === "JWT Token Expired") {
            console.log("attempting to update token");
            const token2 = await getAuthToken(apolloClient);
            (window as any).Twilio.Device.updateToken(token2);
          }
        });
      }
    }
  }
};

const answerCall: AnswerCall = async () => {
  // const token = await getAuthToken(apolloClient);
  // (window as any).Twilio.Device.updateToken(token);
  if (goodToGo === true && !currentCall) {
    currentCall = (window as any).Twilio.Device.connect({
      AgentAction: "answerCall"
    });
  }
};

const endCall: EndCall = async () => {
  if (goodToGo === true && currentCall) {
    (window as any).Twilio.Device.disconnectAll();
    currentCall = undefined;
  }
};

const toggleMuteCall: ToggleMuteCall = async () => {
  if (currentCall) {
    currentCall.mute(!muted);
    muted = !muted;
  }
};

const makeCall: MakeCall = async (phoneNumber) => {
  if (goodToGo === true && !currentCall) {
    const formattedPhoneNumber = phoneNumber.replace(/\s/g, "");
    currentCall = (window as any).Twilio.Device.connect({
      AgentAction: formattedPhoneNumber
    });
  }
};

const sendDigit: SendDigit = async (digit) => {
  if (currentCall) {
    currentCall.sendDigits(digit);
  }
};

export const useTwilio = (): [
  AnswerCall,
  EndCall,
  MakeCall,
  ToggleMuteCall,
  SendDigit,
  boolean
] => {
  const client = useApolloClient();

  const [hasInitialised, setHasInitialised] = useState(false);

  const doInitialise = useCallback(() => {
    initialise(client);
    setHasInitialised(true);
  }, [client]);

  useEffect(() => {
    if (!hasInitialised) {
      const eventKey = windowEvent.click.subscribe(doInitialise);
      return () => {
        windowEvent.click.unsubscribe(eventKey);
      };
    }
    return () => {};
  }, [hasInitialised, doInitialise]);

  return [
    answerCall,
    endCall,
    makeCall,
    toggleMuteCall,
    sendDigit,
    hasInitialised
  ];
};

export default () => {};
