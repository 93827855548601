import React from "react";
import css from "styled-jsx/css";
import Button from "./button";

const styles = css`
  .loader:not(.small) {
    margin-top: 20vh;
  }
`;

const Loader = ({ small = false }: { small?: boolean }) => {
  return (
    <div className={`loader ${small ? "small" : ""}`}>
      <Button loading fullWidth>
        <span />
      </Button>
      <style jsx>{styles}</style>
    </div>
  );
};

export default Loader;
