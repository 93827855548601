import { gql } from "@apollo/client";

const setShowSupport = (_, obj, { cache }) => {
  const data = {
    showSupport: obj.showSupport
  };
  cache.writeQuery({
    query: gql`
      query showSupport {
        showSupport @client
      }
    `,
    data
  });
  return null;
};

const setOpenTicket = (_, obj, { cache }) => {
  const data = {
    openTicket: obj.openTicket
  };
  cache.writeQuery({
    query: gql`
      query openTicket {
        openTicket @client
      }
    `,
    data
  });
  return null;
};

const setChatOpen = (_, obj, { cache }) => {
  const data = {
    chatOpen: obj.chatOpen
  };
  cache.writeQuery({
    query: gql`
      query chatOpen {
        chatOpen @client
      }
    `,
    data
  });
  return null;
};

const setOnCall = (_, obj, { cache }) => {
  const data = {
    onCall: obj.onCall
  };
  cache.writeQuery({
    query: gql`
      query onCall {
        onCall @client
      }
    `,
    data
  });
  return null;
};

const acceptStartScreen = (_, obj, { cache }) => {
  const data = {
    startScreenAccepted: obj.startScreenAccepted
  };
  cache.writeQuery({
    query: gql`
      query startScreenAccepted {
        startScreenAccepted @client
      }
    `,
    data
  });
  return null;
};

export default {
  setShowSupport,
  setOpenTicket,
  setChatOpen,
  setOnCall,
  acceptStartScreen
};
