/* eslint-disable react/button-has-type */
import React, { MouseEvent, FormEvent, useState, useContext } from "react";
import css from "styled-jsx/css";
import stylingConfig from "../styling/config";
import ReactSVG from "../lib/svg";
import { getAlpha } from "../lib/utils";
import { ThemeContext } from "../lib/contexts";

const styles = css`
  button {
    display: block;
    min-width: 110px;
    height: 38px;
    border: none;
    text-align: center;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    font-family: WaggelNunito, Helvetica, Arial, sans-serif;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${stylingConfig.colours.darkGreyBlue};
    border-radius: 6px;
    transition: all 0.01s cubic-bezier(0.4, 0, 0.2, 1);
  }
  button .loader {
    transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -19px;
    border-radius: 30px;
  }
  button.wrapText {
    white-space: normal;
  }
  button.primary {
    border: 2px solid ${stylingConfig.colours.waggelBlue};
    background: none;
  }
  button.primary:hover,
  button.primary:focus {
    background-color: ${stylingConfig.colours.clearCeruleanBlue};
  }
  button.primary.selected {
    background-color: ${stylingConfig.colours.beefBlue};
  }
  button.primary .loader {
    background-color: ${stylingConfig.colours.clearCeruleanBlue};
  }
  button.primary .loader path {
    fill: ${stylingConfig.colours.waggelBlue};
  }
  button.primary.theme-white {
    color: ${stylingConfig.colours.koda};
    border-color: ${stylingConfig.colours.koda};
  }
  button.primary.theme-white:not(.disabled):hover,
  button.primary.theme-white:not(.disabled):focus {
    background-color: ${getAlpha(stylingConfig.colours.koda, 0.2)};
  }
  button.primary.theme-minimal {
    color: ${stylingConfig.colours.darkGreyBlue};
    border-color: ${stylingConfig.colours.paleGrey};
    background-color: ${stylingConfig.colours.paleGrey};
  }
  button.primary.theme-minimal:not(.disabled):hover,
  button.primary.theme-minimal:not(.disabled):focus {
    background-color: ${stylingConfig.colours.paleGreyThree};
    border-color: ${stylingConfig.colours.paleGreyThree};
  }
  button.primary.theme-green {
    color: ${stylingConfig.colours.darkGreyBlue};
    border-color: ${stylingConfig.colours.greenBlue};
  }
  button.primary.theme-green:not(.disabled):hover,
  button.primary.theme-green:not(.disabled):focus {
    background-color: ${stylingConfig.colours.clearGreenBlue};
  }
  button.primary.theme-red {
    color: ${stylingConfig.colours.darkGreyBlue};
    border-color: ${stylingConfig.colours.warmPink};
  }
  button.primary.theme-red:not(.disabled):hover,
  button.primary.theme-red:not(.disabled):focus {
    background-color: ${getAlpha(stylingConfig.colours.warmPink, 0.1)};
  }

  button.primary.theme-green.disabled,
  button.primary.theme-red.disabled,
  button.primary.theme-red-white.disabled {
    border-color: ${stylingConfig.colours.bluishSteel};
  }

  button.primary.globalTheme-supportChat {
    border-radius: 2px;
    height: 40px;
    text-transform: uppercase;
  }

  button.primary.theme-red-white {
    color: ${stylingConfig.colours.koda};
    border-color: ${stylingConfig.colours.warmPink};
  }
  button.primary.theme-red:not(.disabled):hover,
  button.primary.theme-red:not(.disabled):focus {
    background-color: ${getAlpha(stylingConfig.colours.warmPink, 0.1)};
  }

  button.primary.globalTheme-analytics {
    border: none;
    border-radius: 20px;
    height: 46px;
    color: ${stylingConfig.colours.kodaWhite};
    font-size: 14px;
    font-weight: 700;
  }
  button.primary.globalTheme-analytics.theme-dark {
    background-color: ${stylingConfig.colours.bluishBlack};
  }
  button.primary.globalTheme-analytics.theme-dark.disabled {
    color: ${stylingConfig.colours.otherGrey};
    background-color: ${stylingConfig.colours.bluishLight};
  }
  button.primary.globalTheme-analytics.theme-dark:hover,
  button.primary.globalTheme-analytics.theme-dark:active {
    background-color: ${stylingConfig.colours.bluishLight};
  }
  button.primary.globalTheme-analytics.theme-light {
    background-color: ${stylingConfig.colours.bluishDark};
  }
  button.primary.globalTheme-analytics.theme-light.disabled {
    color: ${stylingConfig.colours.otherGrey};
    background-color: ${stylingConfig.colours.bluishLight};
  }
  button.primary.globalTheme-analytics.theme-light:hover,
  button.primary.globalTheme-analytics.theme-light:active {
    background-color: ${stylingConfig.colours.bluishLight};
  }

  button.secondary {
    color: ${stylingConfig.colours.ceruleanBlue};
    border: none;
    background-color: ${stylingConfig.colours.paleGreyTwo};
    box-shadow: 0px 3px ${stylingConfig.colours.ironGrey};
  }
  button.secondary:hover {
    box-shadow: 0px 2px ${stylingConfig.colours.ironGrey};
    top: 1px;
  }
  button.secondary:focus {
    box-shadow: 0px 0px ${stylingConfig.colours.ironGrey};
    top: 3px;
  }
  button.secondary.theme-white {
    background-color: ${stylingConfig.colours.koda};
    box-shadow: 0px 3px ${stylingConfig.colours.paleGrey};
  }
  button.secondary.theme-white:hover {
    box-shadow: 0px 2px ${stylingConfig.colours.paleGrey};
  }
  button.secondary.theme-white:focus {
    box-shadow: 0px 0px ${stylingConfig.colours.paleGrey};
  }
  button.tertiary {
    background: none;
    border: none;
    border-radius: 0;
    display: inline-block;
    color: ${stylingConfig.colours.ceruleanBlue};
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    height: auto;
  }
  button.tertiary.theme-waggelBlue {
    color: ${stylingConfig.colours.waggelBlue};
  }
  button.tertiary.theme-dark {
    color: ${stylingConfig.colours.darkGreyBlue};
  }
  button.tertiary:hover,
  button.tertiary:focus {
    background: none;
    border: none;
    color: ${stylingConfig.colours.waterBlue};
  }
  button.tertiary.disabled {
    background: none;
    color: ${stylingConfig.colours.ceruleanBlue};
  }
  button.tertiary.disabled:hover,
  button.tertiary.disabled:focus {
    background: none;
    color: ${stylingConfig.colours.ceruleanBlue};
  }
  button.globalTheme-analytics.tertiary {
    font-size: 16px;
    color: ${stylingConfig.colours.waggelBlue};
  }
  button.globalTheme-analytics.tertiary .children {
    font-family: WaggelBrother, Helvetica, Arial, sans-serif;
  }
  button.tertiary:hover,
  button.tertiary:focus {
    background: none;
    border: none;
    color: ${stylingConfig.colours.deepBlue};
  }
  button.danger {
    border: none;
    background-color: ${stylingConfig.colours.coralPink};
    box-shadow: 0px 3px ${stylingConfig.colours.mandyPink};
    color: #fff;
  }
  button.danger:hover {
    box-shadow: 0px 2px ${stylingConfig.colours.mandyPink};
    top: 1px;
  }
  button.danger:focus {
    box-shadow: 0px 0px ${stylingConfig.colours.mandyPink};
    top: 3px;
  }

  button.noPadding {
    padding: 0;
  }

  button.small {
    font-size: 14px;
    font-weight: 400;
    min-width: auto;
    height: 30px;
    padding: 0 3px;
    border-radius: 10px;
  }

  button.medium-small {
    min-width: auto;
    font-size: 16px;
  }

  button.large {
    height: 60px;
  }

  button.round {
    width: 110px;
    border-radius: 55px;
    height: 110px;
    min-width: initial;
    font-size: 28px;
    padding: 0;
  }

  button .children {
    transition: opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  }
  button.center {
    margin-left: auto;
    margin-right: auto;
  }
  button.left {
    text-align: left;
  }
  button.right {
    text-align: right;
  }
  button.fullWidth {
    width: 100%;
  }
  button.disabled {
    background-color: ${stylingConfig.colours.paleGrey};
    color: ${stylingConfig.colours.steel};
    border-color: ${stylingConfig.colours.paleGreyThree};
    cursor: default;
  }
  button.disabled:hover,
  button.disabled:focus {
    background-color: ${stylingConfig.colours.paleGrey};
  }
  button.loading .children,
  button.loading .icon {
    opacity: 0;
  }
  button.loading {
    height: 38px !important;
    min-width: 0 !important;
    cursor: default !important;
    background: none !important;
    border: none;
  }
  button.loading .loader {
    border-radius: 30px;
    width: 38px !important;
    opacity: 1;
    animation: rotating 1.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
  }
  @media screen and (max-width: ${stylingConfig.breakpoints.m}) {
    button.fullWidthOnTablet {
      width: 100%;
    }
  }
  @media screen and (max-width: ${stylingConfig.breakpoints.s}) {
    button.fullWidthOnMobile {
      width: 100%;
    }
  }
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .icon {
    float: left;
    height: 100%;
    border-right: 2px solid ${stylingConfig.colours.waggelBlue};
    display: flex;
    align-items: center;
    padding-right: 10px;
    margin-left: -6px;
    margin-right: 16px;
  }
  button.theme-red .icon {
    border-color: ${stylingConfig.colours.coralPink};
  }
  button.theme-green .icon {
    border-color: ${stylingConfig.colours.greenBlue};
  }
  button.disabled .icon {
    border-color: ${stylingConfig.colours.paleGreyThree};
  }
  .icon.backArrow {
    padding-right: 12px;
    margin-left: -4px;
    margin-right: 16px;
  }
  .children {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export interface ButtonProps {
  ariaLabel?: string;
  className?: string;
  fullWidth?: boolean;
  loading?: boolean;
  fullWidthOnMobile?: boolean;
  fullWidthOnTablet?: boolean;
  onClick?: (
    e: any,
    setInternalLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  children: React.ReactNode;
  purpose?: "primary" | "secondary" | "tertiary" | "danger";
  size?: "large" | "medium" | "medium-small" | "small";
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  align?: string;
  theme?:
    | "blue"
    | "red"
    | "green"
    | "dark"
    | "white"
    | "minimal"
    | "light"
    | "red-white";
  wrapText?: boolean;
  round?: boolean;
  backArrow?: boolean;
  minWidth?: number;
  icon?: string;
  selected?: boolean;
  noPadding?: boolean;
}

/* eslint-disable max-len */
const Button = ({
  ariaLabel = "",
  className = "",
  fullWidth = false,
  loading = false,
  fullWidthOnMobile = false,
  fullWidthOnTablet = false,
  onClick = () => {},
  children,
  purpose = "primary",
  size = "medium",
  disabled = false,
  type = "button",
  align = "",
  theme = "blue",
  wrapText = false,
  round = false,
  backArrow = false,
  minWidth = 160,
  icon = "",
  selected = false,
  noPadding = false
}: ButtonProps) => {
  const [internalLoading, setInternalLoading] = useState(false);
  const globalTheme = useContext(ThemeContext);

  const actuallyLoading = loading || internalLoading;
  return (
    <button
      aria-label={ariaLabel}
      disabled={disabled}
      onClick={
        actuallyLoading || disabled
          ? (e) => {
              e.preventDefault();
            }
          : (e) => {
              onClick(e, setInternalLoading);
            }
      }
      className={`button theme-${theme} globalTheme-${globalTheme} ${purpose} ${align} ${size} ${className} ${
        disabled ? "disabled" : ""
      } ${fullWidth ? "fullWidth" : ""} ${
        fullWidthOnMobile ? "fullWidthOnMobile" : ""
      } ${fullWidthOnTablet ? "fullWidthOnTablet" : ""} ${
        wrapText ? "wrapText" : ""
      } ${actuallyLoading ? "loading" : ""} ${round ? "round" : ""} ${
        selected ? "selected" : ""
      } ${noPadding ? "noPadding" : ""}`}
      type={type}
      style={size === "small" || size === "medium-small" ? {} : { minWidth }}
    >
      <svg
        className="loader"
        width="38"
        height="38"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* <path
          d="M38.82 9.092l-2.122 2.121c-7.018-7.018-18.437-7.018-25.455 0L9.12 9.092 7 6.97c9.373-9.373 24.568-9.373 33.941 0L38.82 9.09z"
          fillRule="evenodd"
        /> */}
        <path
          d="M30.7,7.2L29,8.9c-5.6-5.6-14.6-5.6-20.2,0L7.2,7.2L5.5,5.6C13-1.9,25-1.9,32.4,5.6L30.7,7.2L30.7,7.2z"
          fillRule="evenodd"
        />
      </svg>

      {backArrow && (
        <div className="icon backArrow">
          <img
            height="11"
            width="6"
            alt="previous"
            src={`${process.env.staticUrl}/admin-icon-back.svg`}
          />
        </div>
      )}
      {icon && (
        <div className="icon">
          <img alt="" src={icon} />
        </div>
      )}
      <div className="children">
        <span>{children}</span>
      </div>
      <style jsx>{styles}</style>
    </button>
  );
};
/* eslint-enable max-len */

export default Button;
