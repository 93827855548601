import React from "react";
import { Auth } from "./auth";

export const ThemeContext = React.createContext("default");
export const DeviceIdContext = React.createContext(undefined);
export const SessionIdContext = React.createContext(undefined);
export const AuthContext =
  React.createContext<[Auth, React.Dispatch<React.SetStateAction<Auth>>]>(
    undefined
  );

export default {};
