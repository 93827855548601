import { Policy } from "../types/graphql";

export const getInsuredFromDate = (policies: Policy[]) => {
  // sort in descending startDate, latest first
  const sortedPolicies = policies.slice().sort((a, b) => {
    if (a.startDate < b.startDate) return 1;
    if (a.startDate > b.startDate) return -1;
    return 0;
  });

  let joinedDate = sortedPolicies[0].startDate;

  sortedPolicies.some((policy) => {
    if (policy.status === "cancelled") {
      return true;
    }
    joinedDate = policy.startDate;
    return false;
  });

  return joinedDate;
};

export const camelise = (text: string) => {
  return text.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const camelCaseToTitleCase = (camelCaseString: string) => {
  const result = camelCaseString
    .replace(/([a-z])([A-Z][a-z])/g, "$1 $2")
    .replace(/([A-Z][a-z])([A-Z])/g, "$1 $2")
    .replace(/([a-z])([A-Z]+[a-z])/g, "$1 $2")
    .replace(/([A-Z]+)([A-Z][a-z][a-z])/g, "$1 $2")
    .replace(/([a-z]+)([A-Z0-9]+)/g, "$1 $2")
    .replace(/([A-Z]+)([A-Z][a-rt-z][a-z]*)/g, "$1 $2")
    .replace(/([0-9])([A-Z][a-z]+)/g, "$1 $2")
    .replace(/([A-Z]{2,})([0-9]{2,})/g, "$1 $2")
    .replace(/([0-9]{2,})([A-Z]{2,})/g, "$1 $2")
    .trim();

  return `${result.charAt(0).toUpperCase()}${result.slice(1)}`;
};

export const possessiveName = (name: string) =>
  name.slice(-1) === "s" ? `${name}’` : `${name}’s`;

/* eslint-disable no-bitwise, no-nested-ternary */
export const lightenDarkenColor = (color, percent) => {
  const num = parseInt(color.slice(1), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const B = ((num >> 8) & 0x00ff) + amt;
  const G = (num & 0x0000ff) + amt;

  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)
  )
    .toString(16)
    .slice(1)}`;
};

export const sortObjectListByKey = <T>(
  list: Array<T>,
  key: string,
  asc: boolean = true
): Array<T> => {
  return list.slice().sort((a, b) => {
    if (!a[key]) return asc ? -1 : 1;
    if (!b[key]) return asc ? 1 : -1;
    if (a[key] > b[key]) return asc ? 1 : -1;
    if (a[key] < b[key]) return asc ? -1 : 1;
    return 0;
  });
};

export default {};
