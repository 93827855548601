import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import css from "styled-jsx/css";
import Link from "next/link";
import stylingConfig from "../../styling/config";
import PhoneDialler from "../../components/new/phoneDialler";
import { ThemeContext } from "../../lib/contexts";
import { GET_SELF } from "../header";
import Button2d from "../../components/new/button2d";
import { HeaderSelfInfoQuery } from "../../types/graphql";

const styles = css`
  .header {
    width: 100%;
    background-color: ${stylingConfig.colours.penny};
  }
  .wrapper {
    margin: 0 auto;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: ${stylingConfig.colours.penny};
    justify-content: space-between;
  }
  .leftWrapper {
    display: flex;
    align-items: center;
    padding: 0 30px;
  }
  .logo,
  .logo img {
    height: 33px;
  }
  .separator {
    margin: 0 40px;
    width: 2px;
    height: 36px;
    background: ${stylingConfig.colours.neutral02};
  }
  .logout {
    width: 160px;
  }
  .phoneWrapper {
    position: relative;
  }
  button.phone {
    background: ${stylingConfig.colours.koda};
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: none;
    margin: 0 0 0 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease 0.3s;
  }
  button.phone:hover,
  button.phone:active {
    background: ${stylingConfig.colours.paleBlue};
  }
  .rightWrapper {
    background: ${stylingConfig.colours.koda};
  }
`;

const Header = () => {
  // used to ensure access token is kept valid for the whole app (especially for the support sidebar)
  useQuery<HeaderSelfInfoQuery>(GET_SELF, {
    pollInterval: 1200000
  });

  const [phoneDiallerOpen, togglePhoneDialler] = useState(false);

  return (
    <ThemeContext.Provider value="supportChat">
      <header className="header" id="waggelHeader">
        <div className="wrapper">
          <div className="leftWrapper">
            <Link href="/">
              <a className="logo">
                <img
                  alt="Waggel Pet Insurance"
                  src={`${process.env.staticUrl}/waggel-logo-full_07-01-2020.svg`}
                  height={33}
                  width={113}
                />
              </a>
            </Link>
            <div className="separator" />
            <div className="logout">
              <Link href="/logout" passHref>
                <Button2d dark>Logout</Button2d>
              </Link>
            </div>
            <div className="phoneWrapper">
              <button
                className="phone"
                type="button"
                title="Dial phone"
                onClick={() => {
                  togglePhoneDialler(!phoneDiallerOpen);
                }}
              >
                <img
                  className="phoneImg"
                  alt="phone"
                  src={`${process.env.staticUrl}/icon-admin-phone.svg`}
                />
              </button>
              {phoneDiallerOpen && <PhoneDialler />}
            </div>
          </div>
          <div className="rightWrapper" />
        </div>

        <style jsx>{styles}</style>
      </header>
    </ThemeContext.Provider>
  );
};

export default Header;
