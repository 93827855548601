import { gql } from "@apollo/client";

export default gql`
  extend type Query {
    showSupport: Boolean
    openTicket: String
    chatOpen: Boolean
    onCall: String
    startScreenAccepted: Boolean
  }

  extend type Mutation {
    setShowSupport(showSupport: Boolean!): Boolean
    setOpenTicket(openTicket: String!): String
    setChatOpen(chatOpen: Boolean!): Boolean
    setOnCall(onCall: String!): String
    acceptStartScreen: Boolean
  }
`;
