import React, { useContext } from "react";
import css from "styled-jsx/css";
import Router from "next/router";
import stylingConfig from "../styling/config";
import { ThemeContext } from "../lib/contexts";
import { User } from "../types/graphql";

const styles = css`
  .wrapper {
    height: 40px;
    display: flex;
    font-family: WaggelNunito, Helvetica, Arial, sans-serif;
    font-size: 14px;
    box-sizing: border-box;
  }

  .image__wrapper {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .user__image {
    height: 40px;
    width: 40px;
    box-sizing: border-box;
    border-radius: 100%;
  }

  .right {
    margin-left: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .name {
    color: ${stylingConfig.colours.darkGreyBlue};
    font-weight: 700;
    font-size: 14px;
  }
  .analytics .name {
    color: ${stylingConfig.colours.koda};
  }

  .logout {
    color: ${stylingConfig.colours.warmPink};
    cursor: pointer;
    background: none;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    padding: 0;
  }
`;

const UserCard = ({ user }: { user: User }) => {
  let profilePictureSource = `${process.env.staticUrl}/icon-placeholder-profile-picture-square.svg`;

  if (user.hasProfilePicture) {
    profilePictureSource = `${process.env.generalUploadsUrl}/userProfilePicture-${user.id}.png`;
  }
  const theme = useContext(ThemeContext);

  return (
    <div className={`wrapper ${theme}`}>
      <div className="image__wrapper">
        <img className="user__image" src={profilePictureSource} alt="User" />
      </div>
      <div className="right">
        <div className="name">Hello, {user.firstName}</div>
        <button
          className="logout"
          type="button"
          onClick={() => Router.push("/logout")}
        >
          Logout
        </button>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default UserCard;
