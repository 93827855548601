/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable camelcase */
import React, { useState } from "react";
import css from "styled-jsx/css";
import {
  distanceInWordsStrict,
  differenceInCalendarDays,
  format,
  differenceInMinutes,
  differenceInCalendarYears
} from "date-fns";
import Link from "next/link";
import stylingConfig from "../styling/config";
import { useNewTimer } from "../lib/hooks";
import Button from "./button";
import ChatTrainingModal from "./chatTrainingModal";
import { SupportTicketReply } from "../types/graphql";

const styles = css`
  .container {
    margin: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${stylingConfig.colours.bluishBlack};
  }
  .container.chat.self,
  .container.chat.automated {
    align-items: flex-end;
  }
  .bubble {
    background-color: ${stylingConfig.colours.softBlue};
    padding: 14px;
    border-radius: 2px;
    font-size: 16px;
    display: inline-block;
    white-space: pre-wrap;
    max-width: 326px;
  }
  .automated .bubble {
    background-color: ${stylingConfig.colours.bluishSteel};
    color: ${stylingConfig.colours.koda};
  }
  .otherPerson .bubble {
    background-color: ${stylingConfig.colours.paleGreyTwo};
    color: ${stylingConfig.colours.darkGreyBlue};
  }
  .statusText {
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
  }
  .meta {
    font-size: 12px;
    margin-top: 5px;
  }
  .recording {
    margin-top: 20px;
  }
`;

const ChatMessage = ({
  reply,
  disabled = false,
  firstName
}: {
  reply: SupportTicketReply;
  disabled?: boolean;
  firstName?: string;
}) => {
  const [showTrainingModal, setShowTrainingModal] = useState(false);

  const date = useNewTimer(new Date(), 30);
  let dateString = "Just now";
  if (differenceInMinutes(date, reply.addedAt) > 1) {
    dateString = `${distanceInWordsStrict(reply.addedAt, date)} ago`;
  }
  if (differenceInCalendarDays(date, reply.addedAt) > 0) {
    dateString = format(reply.addedAt, "h:mma [on] ddd Do MMM");
  }
  if (differenceInCalendarYears(date, reply.addedAt) > 0) {
    dateString = format(reply.addedAt, "h:mma [on] ddd Do MMM YYYY");
  }

  let who = "self";
  if (reply.isCustomer) {
    who = "otherPerson";
  } else if (reply.userId === "automated") {
    who = "automated";
  }

  let name;
  if (reply.userId === "automated") {
    name = "Automated message";
  } else if (reply.user) {
    name = reply.user.firstName;
  } else if (firstName) {
    name = firstName;
  }

  return (
    <div>
      <div className={`container ${who} ${reply.medium}`}>
        {reply.medium === "chat" && <div className="bubble">{reply.text}</div>}
        {reply.medium === "status" && (
          <div className="statusText">{reply.text}</div>
        )}
        {(reply.medium === "smartlookPlayUrl" ||
          reply.medium === "fullstoryPlayUrl") && (
          <Link href={reply.text}>
            <a target="_blank">
              <Button>View session</Button>
            </a>
          </Link>
        )}
        {reply.intentId && !reply.intentTrained && (
          <div className="meta trainButton">
            <Button
              purpose="tertiary"
              size="small"
              onClick={() => setShowTrainingModal(true)}
            >
              Train this message
            </Button>
          </div>
        )}
        {(reply.medium === "status" || reply.medium === "chat") && (
          <div className="meta">
            {name && `${name}, `}
            {dateString}
          </div>
        )}
        {reply.medium === "status" &&
          reply.text === "Call ended" &&
          reply.recordingUrl && (
            <div className="recording">
              <audio controls preload="none">
                <source src={reply.recordingUrl} type="audio/mpeg" />
              </audio>
            </div>
          )}
      </div>
      <ChatTrainingModal
        showModal={showTrainingModal}
        setShowModal={setShowTrainingModal}
        reply={reply}
      />
      <style jsx>{styles}</style>
    </div>
  );
};

export default ChatMessage;
