/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext } from "react";
import css from "styled-jsx/css";
import { CSSTransition } from "react-transition-group";
import ReactDOM from "react-dom";
// import FocusTrap from "focus-trap-react";
import stylingConfig from "../styling/config";
import { ThemeContext } from "../lib/contexts";

const modalStyles = css`
  .overlay {
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1000;
    padding: 1.2rem;
    background: rgba(33, 39, 52, 0.5);
    overflow-x: visible;
    overflow-y: visible;
  }
  .analytics .overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  .modal {
    max-width: 800px;
    position: relative;
    padding: 1.2rem;
    background: #ffffff;
    background-clip: padding-box;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    margin: auto;

    box-shadow: none;
    border-radius: 15px;
    padding: 0;
    max-width: 900px;
    width: 900px;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .analytics .modal {
    background-color: ${stylingConfig.colours.bluishDark};
    color: ${stylingConfig.colours.kodaWhite};
    overflow: visible;
  }
  .modal.narrow {
    max-width: 345px;
    width: 345px;
  }
  .closeButton {
    position: absolute;
    top: 14px;
    right: 14px;
    border: none;
    padding: 0;
    background-color: transparent;
    display: flex;

    z-index: 1;
    background-color: ${stylingConfig.colours.koda};
    height: 38px;
    width: 38px;
    border-radius: 19px;
    cursor: pointer;
  }
  .analytics .closeButton {
    background: none;
    top: 20px;
    right: 20px;
    height: 20px;
    width: 20px;
  }
  .closeButton.invert {
    background-color: ${stylingConfig.colours.darkGreyBlue};
  }
  .closeButton:hover,
  .closeButton:focus {
    background-color: ${stylingConfig.colours.paleGreyTwo};
  }
  .closeButton-invert:hover,
  .closeButton-invert:focus {
    background-color: ${stylingConfig.colours.steel};
  }
  .closeIcon {
    left: 10px;
    top: 10px;
    position: absolute;
    width: 38px;
    height: 38px;
  }
  .modalWrapper :global(.transitionEnter) {
    opacity: 0.01;
  }
  .modalWrapper :global(.transitionEnterActive) {
    opacity: 1;
    transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .modalWrapper :global(.transitionExit) {
    opacity: 1;
  }
  .modalWrapper :global(.transitionExitActive) {
    opacity: 0.01;
    transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  @media screen and (max-width: ${stylingConfig.breakpoints.m}) {
    .overlay {
      padding: 0;
    }
    .modal:not(.narrow) {
      width: 100%;
      height: 100%;
      border-radius: 0;
      max-height: 100vh;
      overflow: hidden;
    }
    .overlay.narrow {
      padding: 0 ${stylingConfig.grid.m}px;
    }
  }
`;

const Modal = ({
  open,
  invertCloseButton = false,
  onClose,
  children,
  narrow = false,
  showCloseButton = true,
  width = 900
}: {
  invertCloseButton?: boolean;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  narrow?: boolean;
  showCloseButton?: boolean;
  width?: number;
}) => {
  const [innerHeight, setInnerHeight] = useState(0);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    const wrapper: HTMLElement = document.querySelector("body");
    if (open) {
      wrapper.style.width = "100%";
      wrapper.style.position = "relative";
      wrapper.style.overflow = "hidden";
      // if (theme === "analytics") {
      //   wrapper.style.background = stylingConfig.colours.bluishBlack;
      // }
    } else {
      wrapper.style.position = "";
      wrapper.style.overflow = "";
    }
    return () => {
      wrapper.style.position = "";
      wrapper.style.overflow = "";
    };
  }, [open, theme]);

  useEffect(() => {
    setInnerHeight(window.innerHeight);
  }, []);

  if (!open) return null;

  return ReactDOM.createPortal(
    <div className={`modalWrapper ${theme}`}>
      <CSSTransition
        in={open}
        appear
        classNames={{
          appear: "transitionEnter",
          appearActive: "transitionEnterActive",
          enter: "transitionEnter",
          enterActive: "transitionEnterActive",
          exit: "transitionExit",
          exitActive: "transitionExitActive"
        }}
        timeout={500}
      >
        <div
          className={`overlay ${narrow ? "narrow" : ""}`}
          onClick={() => {
            onClose();
          }}
          role="dialog"
          style={{
            top: document.documentElement.scrollTop,
            bottom:
              document.documentElement.getBoundingClientRect().height -
              document.documentElement.clientHeight -
              document.documentElement.scrollTop
          }}
        >
          <div
            className={`modal ${narrow ? "narrow" : ""}`}
            aria-modal="true"
            onClick={(e) => {
              e.stopPropagation();
            }}
            role="dialog"
          >
            {/* <FocusTrap
              focusTrapOptions={{
                clickOutsideDeactivates: true
              }}
            > */}
            <div>
              {children}
              {showCloseButton && (
                <button
                  className={`closeButton ${invertCloseButton ? "invert" : ""}`}
                  type="button"
                  onClick={() => onClose()}
                >
                  {theme === "analytics" ? (
                    <img
                      alt="close"
                      src={`${process.env.staticUrl}/icon-modal-close.svg`}
                    />
                  ) : (
                    <svg
                      className="closeIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      width={28}
                      height={28}
                      viewBox="0 0 36 36"
                    >
                      {/* eslint-disable max-len */}
                      <path
                        d="M5.755 8.259L.496 3A1.77 1.77 0 0 1 3 .496L8.26 5.755 13.517.496A1.77 1.77 0 0 1 16.021 3L10.763 8.26l5.258 5.258a1.77 1.77 0 0 1-2.504 2.504L8.26 10.763 3 16.02a1.77 1.77 0 0 1-2.504-2.504L5.755 8.26z"
                        fill={invertCloseButton ? "#FFFFFF" : "#34415E"}
                        fillRule="evenodd"
                      />
                      {/* eslint-enable max-len */}
                    </svg>
                  )}
                </button>
              )}
            </div>
            {/* </FocusTrap> */}
          </div>
        </div>
      </CSSTransition>
      <style jsx>{modalStyles}</style>
      <style jsx>{`
        .modal {
          width: ${width}px;
        }
        @media screen and (max-width: ${stylingConfig.breakpoints.m}) {
          .modal:not(.narrow) {
            width: 100%;
            height: 100%;
            border-radius: 0;
            max-height: ${innerHeight}px;
            overflow: auto;
          }
        }
      `}</style>
    </div>,
    document.querySelector(".children-wrapper")
  );
};

export default Modal;
