class SubPub {
  key: number;

  subscribers: any;

  constructor() {
    this.key = 0;
    this.subscribers = {};
    this.subscribe = this.subscribe.bind(this);
    this.publish = this.publish.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
  }

  subscribe(sub: (args: any) => void) {
    this.subscribers[this.key] = sub;
    this.key += 1;
    return this.key - 1;
  }

  publish(args: any) {
    if (this.subscribers)
      Object.keys(this.subscribers).forEach((key) => {
        try {
          this.subscribers[key](args);
        } catch (ignore) {
          // eslint-disable-next-line no-console
          console.log(ignore);
        }
      });
  }

  unsubscribe(key: number) {
    delete this.subscribers[key];
  }
}

export default {
  scroll: new SubPub(),
  resize: new SubPub(),
  click: new SubPub()
};
