/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from "react";
import css from "styled-jsx/css";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import stylingConfig from "../styling/config";
import Row from "./row";
import IconButton from "./iconButton";
import { getAlpha } from "../lib/utils";
import { SupportTicket } from "../types/graphql";

const styles = css`
  .overlay {
    display: flex;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1000;
    padding: 1.2rem;

    background-color: rgba(52, 65, 94, 0.4);
    overflow-x: visible;
    overflow-y: visible;
  }

  .ringer {
    position: relative;
    margin: auto;
    text-align: center;
    color: ${stylingConfig.colours.koda};
    width: 260px;
  }
  .text {
    font-size: 17px;
  }
  .callerId {
    font-size: 26px;
    font-weight: 700;
  }
  .animated {
    margin: 30px 0;
    position: relative;
    height: 260px;
    width: 260px;
  }
  .animated > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .circle {
    box-shadow: 0 0 6px 0 ${getAlpha(stylingConfig.colours.darkGreyBlue, 0.1)};
    background: ${stylingConfig.colours.koda};
    border-radius: 50%;
  }
  .circle1 {
    width: 150px;
    height: 150px;
    animation: pulse1 2s infinite;
  }
  .circle2 {
    animation: pulse2 2s infinite;
    animation-delay: 1s;
  }
  .circle3 {
    animation: pulse3 2s infinite;
    animation-delay: 1s;
  }
  @keyframes pulse1 {
    0%,
    100% {
      width: 150px;
      height: 150px;
    }
    50% {
      width: 180px;
      height: 180px;
    }
  }
  @keyframes pulse2 {
    0% {
      width: 160px;
      height: 160px;
      opacity: 0.5;
    }
    100% {
      width: 240px;
      height: 240px;
      opacity: 0;
    }
  }
  @keyframes pulse3 {
    0% {
      width: 180px;
      height: 180px;
      opacity: 0.4;
    }
    100% {
      width: 320px;
      height: 320px;
      opacity: 0;
    }
  }
  .buttons {
    width: 100%;
  }
  .buttons .text {
    font-weight: 700;
    margin-top: 10px;
  }
`;

const RingingOverlay = ({
  ringing,
  answer,
  ignore,
  ticket
}: {
  ringing: boolean;
  answer: () => void;
  ignore: () => void;
  ticket?: SupportTicket;
}) => {
  useEffect(() => {
    if (ringing) {
      document.getElementById("__next").style.filter = "blur(10px)";

      const { scrollY } = window;
      const { body } = document;
      body.style.width = "100%";
      body.style.position = "fixed";
      body.style.top = `-${scrollY}px`;
    } else {
      const { body } = document;
      const scrollY = body.style.top;
      body.style.position = "";
      body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);

      document.getElementById("__next").style.removeProperty("filter");
      document.documentElement.removeAttribute("style");
    }
  }, [ringing]);

  let callerId = "";
  if (ticket) {
    if (ticket.user) {
      callerId = `${ticket.user.firstName} ${ticket.user.lastName}`;
    } else {
      callerId = "Unknown user";
    }
  }

  if (!ringing || !process.browser) return null;

  return ReactDOM.createPortal(
    <div className="modalWrapper">
      <CSSTransition
        in={ringing}
        appear
        classNames={{
          appear: "transitionEnter",
          appearActive: "transitionEnterActive",
          enter: "transitionEnter",
          enterActive: "transitionEnterActive",
          exit: "transitionExit",
          exitActive: "transitionExitActive"
        }}
        timeout={500}
      >
        <div className="overlay">
          <div className="ringer" aria-modal="true">
            <audio controls={false} autoPlay loop>
              <source
                src={`${process.env.staticUrl}/admin_ringtone.mp3`}
                type="audio/mpeg"
              />
              <track kind="captions" />
              Your browser does not support the HTML5 Audio element.
            </audio>
            <div className="text">Incoming call</div>
            <div className="callerId">{callerId}</div>
            <div className="animated">
              <div className="circle circle3" />
              <div className="circle circle2" />
              <div className="circle circle1" />
              <img
                // height={47}
                // width={47}
                alt="caller"
                src={`${process.env.staticUrl}/icon-phone-unknown-user.svg`}
              />
            </div>
            <div className="buttons">
              <Row justifyContent="space-between">
                <div className="ignore">
                  <IconButton
                    height={66}
                    width={66}
                    alt="ignore"
                    src={`${process.env.staticUrl}/icon-ignore-call.svg`}
                    onClick={ignore}
                  />
                  <div className="text">Ignore</div>
                </div>
                <div className="answer">
                  <IconButton
                    height={66}
                    width={66}
                    alt="answer"
                    src={`${process.env.staticUrl}/icon-answer-call.svg`}
                    onClick={answer}
                  />
                  <div className="text">Answer</div>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </CSSTransition>
      <style jsx>{styles}</style>
    </div>,
    document.body
  );
};

export default RingingOverlay;
