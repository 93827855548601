import {
  InMemoryCache,
  ApolloClient,
  NormalizedCacheObject,
  HttpLink,
  ApolloLink,
  split,
  gql
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { onError } from "@apollo/client/link/error";
import cookie from "cookie";
import { v4 } from "uuid";
import Router from "next/router";

import mutations from "../state/mutations";
import clientSchema from "./clientSchema";

let apolloClient: ApolloClient<NormalizedCacheObject> = null;

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch;
}

const getAccessToken = (refreshToken: string) =>
  fetch(`${process.env.graphQLUrl}/graphql`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include",
    body: JSON.stringify({
      operationName: null,
      variables: {},
      query: `mutation {\n  getToken(clientId: "${process.env.clientId}", grantType: "refreshToken",\n
       authCode: "${refreshToken}") {\n    accessToken\n  }\n}\n`
    })
  })
    .then((res) => res.json())
    .then((res) => {
      if (!res.data || !res.data.getToken || !res.data.getToken.accessToken) {
        throw new Error("Unable to get access token");
      }
      const date = new Date();
      date.setTime(date.getTime() + 28 * 24 * 60 * 60 * 1000);
      document.cookie = `accessToken.${process.env.clientId}=${
        res.data.getToken.accessToken
      };expires=${date.toUTCString()};path=/`;
    });

const handleExpiredAccessToken = async () => {
  if (process.browser) {
    const refreshToken = localStorage.getItem(
      `refreshToken.${process.env.clientId}`
    );
    if (!refreshToken) {
      // logout and reload page
      document.cookie = `accessToken.${process.env.clientId}=;Max-Age=-99999999;path=/`;
      Router.reload();
      return false;
    }

    // make API call to get a new access token
    let valid = true;
    try {
      await getAccessToken(refreshToken);
    } catch (accessTokenError) {
      console.error("ERR", accessTokenError); // eslint-disable-line no-console
      // if it fails
      // logout and reload page
      document.cookie = `accessToken.${process.env.clientId}=;Max-Age=-99999999;path=/`;
      Router.reload();
      valid = false;
    }
    return valid;
  }
  // throw error so server side render fails if access token is expired
  throw new Error("UNAUTHENTICATED");
};

const customFetch = async (uri: string, options: any): Promise<Response> => {
  const opts = {
    ...options,
    headers: { ...(options.headers || {}), requestId: v4() },
    credentials: "include"
  };

  // If we're on the client side, grab the latest access token from the cookie (if it exists)
  if (process.browser && !opts.headers.authentication) {
    const cookies = cookie.parse(document.cookie);
    if (cookies[`accessToken.${process.env.clientId}`]) {
      opts.headers.authentication =
        cookies[`accessToken.${process.env.clientId}`];
    }
  }
  const batch = !!(
    typeof options.headers.batch !== "undefined" && options.headers.batch
  );
  delete opts.headers.batch;

  let validGraphqlCall = true;
  let response = await fetch(uri, opts);
  if (response.status === 401) {
    if (await handleExpiredAccessToken()) {
      const cookies2 = cookie.parse(document.cookie);
      opts.headers.authentication =
        cookies2[`accessToken.${process.env.clientId}`];
      response = await fetch(uri, opts);
    } else {
      validGraphqlCall = false;
    }
  }
  let text: string;
  if (validGraphqlCall) {
    text = await response.text();
    const json = JSON.parse(text);
    let errors;
    if (json.errors) {
      ({ errors } = json);
    } else if (batch) {
      errors = json.reduce((prev, curr) => {
        if (curr.errors) {
          return [...prev, ...curr.errors];
        }
        return prev;
      }, []);
    }

    if (errors && Array.isArray(errors) && errors.length > 0) {
      const authErrors = errors.filter(
        (error) =>
          error.extensions &&
          (error.extensions.code === "UNAUTHENTICATED" ||
            error.extensions.code === "FORBIDDEN")
      );
      // if an authentication error occurred, presume the access token has expired
      if (authErrors.length > 0) {
        if (await handleExpiredAccessToken()) {
          // make the original api call again with the new access token
          const cookies2 = cookie.parse(document.cookie);
          opts.headers.authentication =
            cookies2[`accessToken.${process.env.clientId}`];
          response = await fetch(uri, opts);
          text = await response.text();
        }
      }
    }
  }

  const result: any = {};
  result.ok = true;
  // result.json = () =>
  //   new Promise(resolve => {
  //     resolve(json);
  //   });
  result.text = () =>
    new Promise((resolve) => {
      resolve(text);
    });
  return result;
};

const create = (
  initialState,
  accessToken,
  deviceId,
  sessionId
): ApolloClient<NormalizedCacheObject> => {
  const inMemoryCache = new InMemoryCache({
    typePolicies: {
      Condition: { keyFields: ["id", "name"] },
      PreExistingCondition: { keyFields: ["id", "exclusion", ["name"]] },
      SubLimit: { keyFields: ["id", "policyId"] },
      NewSubLimit: { keyFields: ["id", "claimId"] },
      PolicyEvent: { keyFields: ["id", "policyId"] },
      InvoiceItem: { keyFields: ["lineId"] },
      ClaimVetEmailSent: { keyFields: ["sentAt"] },
      Query: {
        fields: {
          // TODO: implement read and merge for exclusion reviews
          getClaims: {
            read: (cache = {}, { args: { page, ...otherArgs } }) => {
              const serialisedArgs = JSON.stringify(otherArgs);
              const existing = { ...cache[serialisedArgs] };

              const serialisedArgsWithPage = JSON.stringify({
                page,
                ...otherArgs
              });
              const existingPage = { ...cache[serialisedArgsWithPage] };
              if (typeof page !== "undefined") {
                return existingPage;
              }
              return existing;
            },
            merge: (cache = {}, incoming, { args: { page, ...otherArgs } }) => {
              const serialisedArgs = JSON.stringify(otherArgs);
              const existing = cache[serialisedArgs];
              const merged = {
                ...incoming,
                results: [
                  ...((existing && existing.results) || []),
                  ...((incoming && incoming.results) || [])
                ]
              };
              const mergedCache = { ...cache };
              mergedCache[serialisedArgs] = merged;
              if (typeof page !== "undefined") {
                const serialisedArgsWithPage = JSON.stringify({
                  page,
                  ...otherArgs
                });
                mergedCache[serialisedArgsWithPage] = incoming;
              }
              return mergedCache;
            }
          }
        }
      }
    },
    possibleTypes: {}
  }).restore(initialState || {});

  const headers: any = {
    deviceId,
    sessionId
  };
  if (accessToken) {
    headers.authentication = accessToken;
  }
  const httpLinko = new HttpLink({
    uri: `${process.env.graphQLUrl}/graphql`,
    fetch: customFetch,
    headers
  });

  const batchHttpLinko = new BatchHttpLink({
    uri: `${process.env.graphQLUrl}/graphql`,
    fetch: customFetch,
    headers: {
      ...headers,
      batch: true
    }
  });

  const errorLink = onError(({ response, graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (
          extensions &&
          (extensions.code === "UNAUTHENTICATED" ||
            extensions.code === "FORBIDDEN")
        ) {
          response.errors = null;
        }
      });
  });

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([
      errorLink,
      split(
        (operation) => operation.getContext().important === true,
        httpLinko,
        batchHttpLinko
      )
    ]),
    cache: inMemoryCache,
    resolvers: {
      Mutation: {
        ...mutations
      }
    },
    typeDefs: clientSchema
  });
};

const initApollo = (
  initialState = {},
  accessToken?: string,
  deviceId?: string,
  sessionId?: string
) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState, accessToken, deviceId, sessionId);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, undefined, deviceId, sessionId);
  }

  return apolloClient;
};

export default initApollo;
