const breakpoints = {
  xxs: "350px",
  xs: "460px",
  s: "600px",
  m: "768px",
  l: "1024px",
  xl: "1200px",
  xxl: "1440px"
};

const pageMaxWidth = "1300px";

const grid = {
  xxs: 10,
  xs: 16,
  s: 20,
  m: 26,
  l: 30,
  xl: 36
};

const colours = {
  weirdGreen: "#43EB9A",
  // bluishGreen: "#11a65e",
  beefBlue: "#BFEEFF",
  hummingbirdGreen: "#CEF9E3",
  greenBlue: "#34D688",
  algaeGreen: "#22cc7a",
  neonBlue: "#00E0FF",
  azure: "#08AAE6",
  // waggelBlue: "#00BDFF",
  waterBlue: "#1a5176",
  ceruleanBlue: "#2574A9",
  darkGreyBlue: "#34415E",
  mirageBlue: "#1f2940",
  paleGrey: "#E9EDF1",
  paleGreyTwo: "#F3F6F9",
  paleGreyThree: "#d9e0e7",
  solitudeGrey: "#E9F5FF",
  darkSolitudeGrey: "#DAEBFA",
  ironGrey: "#D7DBDF",
  // koda: "#FFFFFF",
  lightBlueGrey: "#E1EDF8",
  silver: "#d5d4d6",
  coolGrey: "#B0BBC2",
  sunYellow: "#FFD939",
  darkCream: "#ffea8f",
  goldenGrass: "#DDA526",
  goldenRod: "#F4CF07",
  pissYellow: "#DDBE16",
  dandelion: "#FBE911",
  blueyGrey: "#9CABB5",
  blueyGreyTwo: "#9AA3B7",
  steel: "#677189",
  cosmosPink: "#FFD6DA",
  coralPink: "#dc143c",
  mandyPink: "#DF4452",
  purpley: "#788cf6",
  bbm: "#10875B",
  clearCeruleanBlue: "rgba(0, 189, 255, 0.1)",
  clearDarkGreyBlue: "rgba(52, 65, 94, 0.2)",
  clearGreenBlue: "rgba(52, 214, 136, 0.1)",
  clearMandyPink: "rgba(223, 68, 82, 0.1)",

  // PRIMARY
  bluishGrey: "#D5DEEB", // formerly --lightPeriwinkle --silver --coolGrey --blueyGrey --blueyGreyTwo
  bluishBlack: "#212734", // formerly --ebonyClay --darkGreyBlue --mirageBlue
  bluishSteel: "#5F7492", // formerly --blueGrey --blueWood --steel
  softBlue: "#BFEEFF", // formerly --beefBlue
  // waggelBlue: "#00BDFF", // --pacificBlue
  deepBlue: "#2975A8", // formerly --jellyBean --waterBlue --ceruleanBlue
  softGold: "#FFEA8F", // formerly --darkCream
  sailorGold: "#FFD939", // formerly --sunYellow --goldenRod --dandelion
  deepGold: "#DDA526", // formerly --goldenGrass --pissYellow

  // SECONDARY
  softPink: "#FFD6DA", // formerly --cosmosPink
  warmPink: "#FF5B7D", // formerly --warmPink --coralPink --mandyPink
  deepPink: "#BC202D", // formerly --rouge
  softGreen: "#CEF9E3", // formerly --hummingbirdGreen
  bluishGreen: "#22CC7A", // formerly --algaeGreen --weirdGreen --greenBlue
  deepGreen: "#15A761",
  softGrey: "#F3F6F9", // formerly --paleGreyTwo --paleGrey --paleGreyFour --solitudeGrey --lilyWhite
  basicGrey: "#D9E0E7", // formerly --paleGreyThree --darkSolitudeGrey --ironGrey --lightBlueGrey
  deepGrey: "#8D9EB5", // formerly --tesco --baliHai
  kodaWhite: "#FFFFFF", // formerly --koda

  // ANALYTICS
  bluishDark: "#303a4d",
  bluishLight: "#39455a",
  otherGrey: "#818ea5",

  // BRANDS
  petplan: "#0052a4",
  tesco: "#8D9EB5", // keep for legacy reasons
  afi: "#E60077",
  visa1: "#454eae",
  visa2: "#414aa7",
  visa3: "#3a439f",
  mastercard1: "#2d3441",
  mastercard2: "#272d3a",
  mastercard3: "#212734",
  amex1: "#2b67b0",
  amex2: "#245ea5",
  amex3: "#1f579b",
  otherCard1: "#5f7492",
  otherCard2: "#596e8b",
  otherCard3: "#4f6482",

  // Brand 2.0
  pennyBlack: "#212734",
  // kodaWhite: "#FFFFFF",
  // paleGreen: "#8FFFE9",
  // seaGreen: "#2FF4C5",
  // darkGreen: "#009995",
  // paleYellow: "#FFF0A9",
  sailorYellow: "#FFE333",
  // orange: "#FD9C27",
  // palePink: "#FFCAD8",
  // cherryPink: "#F43776",
  // darkPink: "#9D0E46",
  // paleGrey: "#F3F6F9",
  paleGrey2: "#E8EDF4",
  grey: "#D1DEE8",
  darkGrey: "#8D9EB5",
  mystic: "#E8EDF2",
  whiteLilac: "#f2f5fa",
  tabasco: "#ac1e16",
  shuttleGrey: "#576375",

  // Waggel Design System colours
  koda: "#FFFFFF",
  neutral01: "#F2F5FA",
  neutral02: "#D1DEE8",
  neutral03: "#8C9EB5",
  penny: "#212633",
  waggelBlue: "#00BDFF",
  seaGreen: "#2EF5C4",
  cherryPink: "#F53875",
  sailor: "#FFE333",
  softPurple: "#5C8EFF",
  darkBlue: "#0674F9",
  darkGreen: "#009994",
  darkPink: "#9D0E45",
  orange: "#FC9C26",
  darkPurple: "#3D55AD",
  paleBlue: "#99E3FF",
  paleGreen: "#8FFFE8",
  palePink: "#FFC9D9",
  paleYellow: "#FFF0A8",
  palePurple: "#BDD5FF",
  disabled: "#576375",
  error: "#DF0C0C",
  confirm: "#81EE5B",
  darkConfirmationGreen: "#3F9322",
  paleConfirmationGreen: "#C8FFB5"
};

const chartColours = [
  "#00bdff",
  "#ffe874",
  "#788cf6",
  "#7aefff",
  "#ff5b7d",
  "#06bea8",
  "#ffa955",
  "#9770cc",
  "#87c0ff",
  "#c9f76e",
  "#ffbda1",
  "#c0d1ff",
  "#1b82f3",
  "#fecf51",
  "#a9e534",
  "#ffd9a8",
  "#74ffb8",
  "#DAC0FF",
  "#b96a49",
  "#ffaabb"
];

const colourList = [
  "#ff8080",
  "#9fc53a",
  "#5bd4ff",
  "#a96cc2",
  "#ccde15",
  "#d17eca",
  "#3490fe",
  "#71aa62",
  "#47b3ff",
  "#ffa84c",
  "#ffce18",
  "#f47eca"
];

export default {
  breakpoints,
  pageMaxWidth,
  grid,
  colours,
  colourList,
  chartColours
};
