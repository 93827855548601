/* eslint-disable react/button-has-type */
import React, { CSSProperties } from "react";
import css from "styled-jsx/css";
import stylingConfig from "../styling/config";

const styles = css`
  button {
    background: none;
    border: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
  button.bordered {
    background: none;
    height: 38px;
    width: 38px;
    border-radius: 6px;
    border: 2px solid ${stylingConfig.colours.waggelBlue};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button[disabled] {
    cursor: default;
  }
  button[disabled].bordered {
    filter: grayscale(100%);
    opacity: 0.4;
  }
`;

const IconButton = ({
  type = "button",
  className = "",
  src,
  width = 20,
  height = 20,
  rotate = 0,
  onClick,
  top = 0,
  alt = "",
  disabled = false,
  theme = "default",
  id = ""
}: {
  type?: "button" | "submit" | "reset";
  className?: string;
  src: string;
  width?: number;
  height?: number;
  rotate?: number;
  onClick?: (e: any) => void;
  top?: number;
  alt?: string;
  disabled?: boolean;
  theme?: "default" | "bordered";
  id?: string;
}) => {
  const imgStyles: CSSProperties = { height };
  if (theme !== "bordered") {
    imgStyles.width = width;
  }
  if (top !== 0) {
    imgStyles.top = `${top}px`;
    imgStyles.position = "relative";
  }
  if (rotate !== 0) {
    imgStyles.transform = `rotate(${rotate}deg)`;
  }
  const buttonStyles: CSSProperties = {};
  if (height && theme !== "bordered") {
    buttonStyles.height = height;
  }
  return (
    <button
      className={`${theme} ${className}`}
      type={type}
      onClick={onClick}
      name={alt}
      disabled={disabled}
      style={buttonStyles}
      title={alt}
      id={id}
    >
      <img style={imgStyles} src={src} alt={alt} />
      <style jsx>{styles}</style>
    </button>
  );
};

export default IconButton;
