/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import css from "styled-jsx/css";
import { gql, useQuery, useMutation } from "@apollo/client";
import Link from "next/link";
import Push from "push.js";
import { useRouter } from "next/router";
import ReactDOM from "react-dom";
import { usePusher } from "../../lib/pusher";
import { useTwilio } from "../../lib/twilio";
import stylingConfig from "../../styling/config";
import IconButton from "../../components/iconButton";
import Button from "../../components/button";
import RingingOverlay from "../../components/ringingOverlay";
import Row from "../../components/row";
import SupportChat from "./supportChat";
import { ThemeContext } from "../../lib/contexts";
import Input from "../../components/input";
import {
  GetAllOpenSupportTicketsQuery,
  GetAllOpenSupportTicketsQueryVariables,
  GetChatOpenQuery,
  GetOnCallQuery,
  GetOpenTicketQuery,
  GetSupportTicketDetailsQuery,
  GetSupportTicketDetailsQueryVariables,
  ReopenTicketMutation,
  ReopenTicketMutationVariables,
  ResolveTicketMutation,
  ResolveTicketMutationVariables,
  SetNewChatOpenMutation,
  SetNewChatOpenMutationVariables,
  SetNewOnCallMutation,
  SetNewOnCallMutationVariables,
  SetNewOpenTicketMutation,
  SetNewOpenTicketMutationVariables,
  SetTicketAsWaitingForMutation,
  SetTicketAsWaitingForMutationVariables,
  SupportTicket
} from "../../types/graphql";

export const GET_OPEN_TICKET = gql`
  query getOpenTicket {
    openTicket @client
  }
`;

export const SET_OPEN_TICKET = gql`
  mutation setNewOpenTicket($openTicket: String!) {
    setOpenTicket(openTicket: $openTicket) @client
  }
`;

export const GET_CHAT_OPEN = gql`
  query getChatOpen {
    chatOpen @client
  }
`;

export const SET_CHAT_OPEN = gql`
  mutation setNewChatOpen($chatOpen: Boolean!) {
    setChatOpen(chatOpen: $chatOpen) @client
  }
`;

export const GET_ON_CALL = gql`
  query getOnCall {
    onCall @client
  }
`;

export const SET_ON_CALL = gql`
  mutation setNewOnCall($onCall: String!) {
    setOnCall(onCall: $onCall) @client
  }
`;

const GET_ALL_OPEN_SUPPORT_TICKETS = gql`
  query getAllOpenSupportTickets($status: String) {
    getSupportTickets(status: $status) {
      id
      status
      subStatus
      userId
      user {
        id
        firstName
        lastName
      }
      deviceId
      createdAt
      closedBy
      isOnline
      tempFirstName
    }
  }
`;

export const GET_TICKET_DETAILS = gql`
  query getSupportTicketDetails($ticketId: ID!) {
    getSupportTicketById(ticketId: $ticketId) {
      id
      status
      userId
      user {
        id
        firstName
      }
      deviceId
      createdAt
      closedBy
      profilePicture
      isOnline
      tempEmail
      tempFirstName
      replies {
        id
        ticketId
        medium
        text
        isCustomer
        addedAt
        hidden
        userId
        user {
          id
          firstName
        }
        intentId
        intentConfidence
        intentTrained
        recordingUrl
      }
    }
  }
`;

const RESOLVE_TICKET = gql`
  mutation resolveTicket($ticketId: ID!) {
    closeSupportTicket(ticketId: $ticketId) {
      id
      status
      userId
      deviceId
      createdAt
      closedBy
      profilePicture
      isOnline
      tempEmail
      tempFirstName
    }
  }
`;

const REOPEN_TICKET = gql`
  mutation reopenTicket($ticketId: ID!) {
    reopenSupportTicket(ticketId: $ticketId) {
      id
      status
      userId
      deviceId
      createdAt
      closedBy
      profilePicture
      isOnline
      tempEmail
      tempFirstName
    }
  }
`;

const SET_TICKET_AS_WAITING_FOR = gql`
  mutation setTicketAsWaitingFor($ticketId: ID!) {
    setSupportTicketAsWaitingFor(ticketId: $ticketId) {
      id
      status
      userId
      deviceId
      createdAt
      closedBy
      profilePicture
      isOnline
      tempEmail
      tempFirstName
    }
  }
`;

const styles = css`
  .headerPortal {
    display: flex;
  }
  .headerBar {
    display: flex;
    width: 472px;
    margin: 10px;
  }
  .headerBar .button {
    margin-right: 14px;
  }
  .headerBar .searchInput {
    flex-grow: 1;
  }
  .sidebar {
    height: calc(100vh - 60px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: ${stylingConfig.colours.softGrey};
    border-left: 1px solid ${stylingConfig.colours.bluishGrey};
  }
  .sidebar.waitingFor {
    background-color: ${stylingConfig.colours.bluishGrey};
  }
  .sidebar.search {
    background-color: ${stylingConfig.colours.deepGrey};
  }
  .twilioWarning {
    font-size: 12px;
    padding: 14px;
    display: flex;
    align-items: center;
  }
  .call {
    background-color: ${stylingConfig.colours.bluishBlack};
    padding: 10px;
    color: ${stylingConfig.colours.koda};
    font-size: 16px;
    font-weight: 600;
  }
  .call img {
    margin: 0 20px 0 6px;
  }
  .call .button {
    margin-left: 14px;
  }
  .buttons {
    background-color: ${stylingConfig.colours.koda};
    flex-shrink: 0;
    display: flex;
    padding: 14px;
    justify-content: center;
  }
  .buttons .button:not(:last-child) {
    padding-right: 14px;
  }
  .buttons .button:last-child {
    padding-right: 0;
  }
  .buttons .button.small:not(:last-child) {
    width: 54px;
  }
  .buttons .button:not(.small) {
    flex-grow: 1;
  }
  .buttons .button.small :global(button) {
    width: 40px;
    border: 2px solid ${stylingConfig.colours.waggelBlue};
    border-radius: 2px;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .buttons .button.small :global(button):hover,
  .buttons .button.small :global(button):focus {
    background-color: ${stylingConfig.colours.clearCeruleanBlue};
  }
  .chatAndTickets {
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    overflow: hidden;
  }
  .tickets {
    width: 90px;
    padding: 15px 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .ticket {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
    height: 70px;
    width: 90px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ticket:hover,
  .ticket:focus,
  .ticket.selected {
    background-color: ${stylingConfig.colours.deepGrey};
  }
  .sidebar.search .ticket:hover,
  .sidebar.search .ticket:focus,
  .sidebar.search .ticket.selected {
    background-color: ${stylingConfig.colours.bluishSteel};
  }
  .ticket img.profilePicture {
    border-radius: 25px;
    height: 50px;
    width: 50px;
  }
  .ticket .circle {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: ${stylingConfig.colours.bluishGrey};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ticket.status-newTicket .circle {
    background-color: ${stylingConfig.colours.bluishGreen};
    box-shadow: 0 0 0px 2px ${stylingConfig.colours.bluishGrey},
      0 0 0px 4px ${stylingConfig.colours.bluishGreen};
  }
  .ticket.status-needsReply .circle {
    background-color: ${stylingConfig.colours.waggelBlue};
  }
  .ticket.status-waiting .circle {
    background-color: ${stylingConfig.colours.purpley};
  }
  .ticket .initials {
    font-size: 18px;
    font-weight: 700;
    font-family: WaggelBrother, Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    color: ${stylingConfig.colours.koda};
  }
  .ticket.status-dormant .initials {
    color: ${stylingConfig.colours.deepGrey};
  }
  .ticket .icon {
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .ticket.status-dormant img.profilePicture {
    filter: grayscale(100%);
  }
`;

const SupportSidebar = ({
  auth
}: {
  auth: {
    userId?: string;
    hasAccessToken: boolean;
  };
}) => {
  // push notifications
  useEffect(() => {
    Push.Permission.request();
  });

  const [otherTyping, setOtherTyping] = useState("");
  const [otherOnline, setOtherOnline] = useState("neither");

  const openTicketQuery = useQuery<GetOpenTicketQuery>(GET_OPEN_TICKET);
  let openTicket = "";
  if (!openTicketQuery.loading && openTicketQuery.data) {
    ({ openTicket } = openTicketQuery.data);
  }

  const [openTicketMutation] =
    useMutation<SetNewOpenTicketMutation, SetNewOpenTicketMutationVariables>(
      SET_OPEN_TICKET
    );

  const setOpenTicket = (newVal: string) => {
    setOtherTyping("");
    setOtherOnline("neither");
    openTicketMutation({
      variables: {
        openTicket: newVal
      },
      refetchQueries: [
        {
          query: GET_TICKET_DETAILS,
          variables: {
            ticketId: newVal
          }
        }
      ]
    });
  };

  const chatOpenQuery = useQuery<GetChatOpenQuery>(GET_CHAT_OPEN);
  let chatOpen = false;
  if (chatOpenQuery.data) {
    ({ chatOpen } = chatOpenQuery.data);
  }

  const [chatOpenMutation] =
    useMutation<SetNewChatOpenMutation, SetNewChatOpenMutationVariables>(
      SET_CHAT_OPEN
    );

  const setChatOpen = (newVal: boolean) => {
    chatOpenMutation({
      variables: {
        chatOpen: newVal
      }
    });
  };

  const onCallQuery = useQuery<GetOnCallQuery>(GET_ON_CALL);
  let onCall = "";
  if (onCallQuery.data) {
    ({ onCall } = onCallQuery.data);
  }

  const [onCallMutation] =
    useMutation<SetNewOnCallMutation, SetNewOnCallMutationVariables>(
      SET_ON_CALL
    );

  const setOnCall = (newVal: string) => {
    onCallMutation({
      variables: {
        onCall: newVal
      }
    });
  };

  const [showWaitingFor, setShowWaitingFor] = useState(false);

  const allOpenSupportTicketsQuery = useQuery<
    GetAllOpenSupportTicketsQuery,
    GetAllOpenSupportTicketsQueryVariables
  >(GET_ALL_OPEN_SUPPORT_TICKETS, {
    variables: {
      status: showWaitingFor ? "waitingFor" : "open"
    }
  });

  const openTicketDetailsQuery = useQuery<
    GetSupportTicketDetailsQuery,
    GetSupportTicketDetailsQueryVariables
  >(GET_TICKET_DETAILS, {
    variables: {
      ticketId: openTicket
    },
    skip: openTicket === "",
    onCompleted: () => {
      allOpenSupportTicketsQuery.refetch();
    }
  });

  const [answerCall, endCall, makeCall, toggleMuteCall, _, twilioInitialised] =
    useTwilio();
  const [ringing, setRinging] = useState("");
  const [callMuted, setCallMuted] = useState(false);

  const [unreadReplies, setUnreadReplies] = useState<{ [id: string]: number }>(
    {}
  );

  usePusher(auth.userId, {
    newTicket: (data) => {
      // refetch list of tickets

      allOpenSupportTicketsQuery.refetch().then((result) => {
        const updatedUnreadReplies = { ...unreadReplies };
        updatedUnreadReplies[`${data.ticketId}`] =
          (updatedUnreadReplies[`${data.ticketId}`] || 0) + 1;
        setUnreadReplies(updatedUnreadReplies);
        if (
          !document.hasFocus() &&
          result.data &&
          result.data.getSupportTickets &&
          result.data.getSupportTickets.find(
            (t) => `${t.id}` === `${data.ticketId}`
          )
        ) {
          Push.create("New ticket", {
            onClick() {
              window.focus();
              this.close(); // eslint-disable-line react/no-this-in-sfc
            }
          });
        }
      });
    },
    replyToTicket: (data) => {
      allOpenSupportTicketsQuery.refetch();

      if (
        data.isCustomer &&
        !document.hasFocus() &&
        allOpenSupportTicketsQuery.data &&
        allOpenSupportTicketsQuery.data.getSupportTickets &&
        allOpenSupportTicketsQuery.data.getSupportTickets.find(
          (t) => `${t.id}` === `${data.ticketId}`
        )
      ) {
        Push.create("New reply to ticket", {
          onClick() {
            window.focus();
            this.close(); // eslint-disable-line react/no-this-in-sfc
            setOpenTicket(String(data.ticketId));
            setChatOpen(true);
            const tempUnreadReplies = { ...unreadReplies };
            delete tempUnreadReplies[String(data.ticketId)];
            setUnreadReplies(tempUnreadReplies);
          }
        });
      }
      // if the ticket is open, refetch the ticket details
      if (`${data.ticketId}` === openTicket) {
        openTicketDetailsQuery.refetch();
      } else {
        const updatedUnreadReplies = { ...unreadReplies };
        updatedUnreadReplies[`${data.ticketId}`] =
          (updatedUnreadReplies[`${data.ticketId}`] || 0) + 1;
        setUnreadReplies(updatedUnreadReplies);
      }
    },
    typing: (data) => {
      // if the ticket is open, inject typing message
      if (`${data.ticketId}` === openTicket) {
        setOtherTyping(data.userName);
      }
    },
    notTyping: (data) => {
      // if the ticket is open, remove typing message
      if (`${data.ticketId}` === openTicket) {
        setOtherTyping("");
      }
    },
    closedTicket: (data) => {
      // refetch list of tickets
      // if ticket is open, refetch ticket details\
      allOpenSupportTicketsQuery.refetch();
      if (`${data.ticketId}` === openTicket) {
        openTicketDetailsQuery.refetch();
      }
    },
    reopenedTicket: (data) => {
      // refetch list of tickets
      // if ticket is open, refetch ticket details
      allOpenSupportTicketsQuery.refetch();
      if (`${data.ticketId}` === openTicket) {
        openTicketDetailsQuery.refetch();
      }
    },
    online: (data) => {
      // refetch list of tickets
      // if ticket is open, inject online message
      allOpenSupportTicketsQuery.refetch();
      if (
        openTicketDetailsQuery.data &&
        openTicketDetailsQuery.data.getSupportTicketById &&
        `${data.id}` === openTicketDetailsQuery.data.getSupportTicketById.userId
      ) {
        setOtherOnline("online");
      }
    },
    offline: (data) => {
      // refetch list of tickets
      // if ticket is open, inject offline message
      allOpenSupportTicketsQuery.refetch();
      if (
        openTicketDetailsQuery.data &&
        openTicketDetailsQuery.data.getSupportTicketById &&
        `${data.id}` === openTicketDetailsQuery.data.getSupportTicketById.userId
      ) {
        setOtherOnline("offline");
      }
    },
    incomingCall: (data) => {
      if (!onCall) {
        setRinging(`${data.ticketId}`);
      }
      if (!onCall && !document.hasFocus()) {
        Push.create("Incoming call", {
          onClick() {
            window.focus();
            this.close(); // eslint-disable-line react/no-this-in-sfc
            setOpenTicket(String(data.ticketId));
            setChatOpen(true);
            const tempUnreadReplies = { ...unreadReplies };
            delete tempUnreadReplies[String(data.ticketId)];
            setUnreadReplies(tempUnreadReplies);
          }
        });
      }
    },
    callUnavailable: (data) => {
      // console.log("callUnavailable :", data);
    },
    callEnded: (data) => {
      if (`${data.ticketId}` === onCall) {
        setOnCall("");
        setOpenTicket(`${data.ticketId}`);
      }
      if (`${data.ticketId}` === ringing) {
        setRinging("");
      }
    },
    callAnswered: (data) => {
      if (`${data.ticketId}` === ringing) {
        setRinging("");
      }
    }
  });

  let supportTickets: SupportTicket[] = [];

  if (
    !allOpenSupportTicketsQuery.loading &&
    !allOpenSupportTicketsQuery.error &&
    allOpenSupportTicketsQuery.data &&
    allOpenSupportTicketsQuery.data.getSupportTickets &&
    allOpenSupportTicketsQuery.data.getSupportTickets.length > 0
  ) {
    supportTickets = allOpenSupportTicketsQuery.data.getSupportTickets;
  }

  const [search, setSearch] = useState("");

  if (search) {
    supportTickets = supportTickets.slice().filter((t) => {
      return (
        `${t.user && t.user.firstName} ${t.user && t.user.lastName} ${
          t.tempFirstName
        }`
          .toLowerCase()
          .search(search.toLowerCase()) > -1
      );
    });
  }

  let currentlyOpenTicket: SupportTicket | undefined;
  if (openTicket && openTicketDetailsQuery.data) {
    currentlyOpenTicket = openTicketDetailsQuery.data.getSupportTicketById;
  }

  const [resolveTicketMutation] = useMutation<
    ResolveTicketMutation,
    ResolveTicketMutationVariables
  >(RESOLVE_TICKET, {
    optimisticResponse: () => ({
      closeSupportTicket: {
        __typename: "SupportTicket",
        ...currentlyOpenTicket,
        staus: "closed"
      }
    })
  });

  const [reopenTicketMutation] = useMutation<
    ReopenTicketMutation,
    ReopenTicketMutationVariables
  >(REOPEN_TICKET, {
    optimisticResponse: () => ({
      reopenSupportTicket: {
        __typename: "SupportTicket",
        ...currentlyOpenTicket,
        status: "open"
      }
    })
  });

  const [setTicketAsWaitingForMutation] = useMutation<
    SetTicketAsWaitingForMutation,
    SetTicketAsWaitingForMutationVariables
  >(SET_TICKET_AS_WAITING_FOR, {
    optimisticResponse: () => ({
      setSupportTicketAsWaitingFor: {
        __typename: "SupportTicket",
        ...currentlyOpenTicket,
        status: "waitingFor"
      }
    })
  });

  const { route, query } = useRouter();

  const onCallTicket = supportTickets.find((t) => t.id === onCall);
  let onCallWith = "";
  let numberOfOpenTickets = 0;
  if (onCallTicket) {
    if (onCallTicket.user) {
      onCallWith = `${onCallTicket.user.firstName} ${onCallTicket.user.lastName}`;
      numberOfOpenTickets = supportTickets
        .slice()
        .filter((t) => t.userId === onCallTicket.userId).length;
    } else {
      onCallWith = onCallTicket.deviceId;
    }
  }

  if (!auth.hasAccessToken) {
    return null;
  }
  return (
    <ThemeContext.Provider value="supportChat">
      <div
        className={`sidebar ${showWaitingFor ? "waitingFor" : ""} ${
          search ? "search" : ""
        }`}
      >
        {process.browser &&
          document.querySelector("#waggelHeader .rightWrapper") &&
          ReactDOM.createPortal(
            <div className="headerPortal">
              {!twilioInitialised && (
                <div className="twilioWarning">
                  Click anywhere to initialise phone API
                </div>
              )}
              {onCall && (
                <div className="call">
                  <Row justifyContent="space-between">
                    <Row>
                      <img
                        alt="phone"
                        src={`${process.env.staticUrl}/admin-icon-phone-green.svg`}
                      />

                      <div>
                        <div>
                          <strong>On call with {onCallWith}</strong>
                        </div>
                        <div>
                          {numberOfOpenTickets
                            ? `${numberOfOpenTickets} open tickets`
                            : ""}
                        </div>
                      </div>
                    </Row>
                    <div className="button">
                      <Button
                        minWidth={40}
                        theme="white"
                        onClick={() => {
                          toggleMuteCall();
                          setCallMuted(!callMuted);
                        }}
                      >
                        {callMuted ? "Unmute" : "Mute"}
                      </Button>
                    </div>
                    <div className="button">
                      <Button
                        minWidth={40}
                        theme="red-white"
                        onClick={() => {
                          endCall();
                          setOnCall("");
                        }}
                      >
                        End call
                      </Button>
                    </div>
                  </Row>
                </div>
              )}
              {chatOpen && (
                <div className="headerBar">
                  <div className="button">
                    <Button
                      minWidth={40}
                      fullWidth
                      theme={showWaitingFor ? "green" : "blue"}
                      onClick={() => {
                        setShowWaitingFor(!showWaitingFor);
                      }}
                    >
                      {showWaitingFor ? "Active chats" : "Waiting for"}
                    </Button>
                  </div>
                  <div className="searchInput">
                    <Input
                      label="Search"
                      name="search"
                      value={search}
                      handleChange={({ target: { value } }) => setSearch(value)}
                    />
                  </div>
                </div>
              )}
            </div>,
            document.querySelector("#waggelHeader .rightWrapper")
          )}
        <RingingOverlay
          ringing={ringing !== ""}
          ticket={supportTickets.find((t) => t.id === ringing)}
          answer={() => {
            setOnCall(ringing);
            setRinging("");
            setCallMuted(false);
            answerCall();
            setOpenTicket(ringing);
          }}
          ignore={() => {
            setRinging("");
          }}
        />

        {openTicket && (
          <div className="buttons">
            <div className="button small">
              <IconButton
                alt={chatOpen ? "Collapse" : "Expand"}
                width={11}
                height={18}
                rotate={chatOpen ? 0 : 180}
                src={`${process.env.staticUrl}/admin-icon-chervon-right.svg`}
                onClick={() => setChatOpen(!chatOpen)}
              />
            </div>
            {chatOpen && openTicket && currentlyOpenTicket && (
              <>
                {currentlyOpenTicket.userId && (
                  <div className="button small">
                    <Link
                      passHref
                      href={`/user?id=${currentlyOpenTicket.userId}`}
                    >
                      <IconButton
                        alt="Go to user"
                        width={18}
                        height={22}
                        src={`${process.env.staticUrl}/admin-icon-account.svg`}
                      />
                    </Link>
                  </div>
                )}
                {currentlyOpenTicket.userId &&
                  // has phone number && is not on call
                  !onCall && (
                    <div className="button small">
                      <IconButton
                        alt="Call user"
                        width={18}
                        height={22}
                        onClick={() => {
                          setOnCall(currentlyOpenTicket.id);
                          setRinging("");
                          setCallMuted(false);
                          makeCall(`ticketId:${currentlyOpenTicket.id}`);
                        }}
                        src={`${process.env.staticUrl}/admin-icon-phone-black.svg`}
                      />
                    </div>
                  )}
                {currentlyOpenTicket.status !== "closed" &&
                  (currentlyOpenTicket.status !== "waitingFor" ? (
                    <div className="button">
                      <Button
                        minWidth={40}
                        fullWidth
                        onClick={() => {
                          setTicketAsWaitingForMutation({
                            variables: {
                              ticketId: openTicket
                            }
                          }).then(() => {
                            openTicketDetailsQuery.refetch();
                            allOpenSupportTicketsQuery.refetch();
                          });
                        }}
                      >
                        Set as waiting for
                      </Button>
                    </div>
                  ) : (
                    <div className="button">
                      <Button
                        minWidth={40}
                        fullWidth
                        onClick={() => {
                          reopenTicketMutation({
                            variables: {
                              ticketId: openTicket
                            }
                          }).then(() => {
                            openTicketDetailsQuery.refetch();
                          });
                        }}
                      >
                        Set as active
                      </Button>
                    </div>
                  ))}
                {currentlyOpenTicket.status !== "closed" ? (
                  <div className="button">
                    <Button
                      minWidth={40}
                      fullWidth
                      onClick={() => {
                        resolveTicketMutation({
                          variables: {
                            ticketId: openTicket
                          }
                        }).then(() => {
                          openTicketDetailsQuery.refetch();
                          allOpenSupportTicketsQuery.refetch();
                        });
                      }}
                    >
                      Resolve
                    </Button>
                  </div>
                ) : (
                  <div className="button">
                    <Button
                      minWidth={40}
                      fullWidth
                      onClick={() => {
                        reopenTicketMutation({
                          variables: {
                            ticketId: openTicket
                          }
                        }).then(() => {
                          openTicketDetailsQuery.refetch();
                        });
                      }}
                    >
                      Reopen
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        <div className="chatAndTickets">
          {openTicket && chatOpen && (
            <>
              <SupportChat
                openTicket={openTicket}
                openTicketDetailsQuery={openTicketDetailsQuery}
                currentlyOpenTicket={currentlyOpenTicket}
                otherTyping={otherTyping}
                otherOnline={otherOnline}
                reopenTicketMutation={reopenTicketMutation}
              />
              {process.browser &&
                document.querySelector(".app-wrapper") &&
                ReactDOM.createPortal(
                  <div style={{ width: "400px", flexShrink: 0 }} />,
                  document.querySelector(".app-wrapper")
                )}
            </>
          )}
          <div className="tickets">
            {supportTickets.map((ticket) => {
              let initials = "";
              if (ticket.user) {
                initials = `${ticket.user.firstName[0]}${ticket.user.lastName[0]}`;
              } else {
                initials = ticket.tempFirstName && ticket.tempFirstName[0];
              }
              return (
                <button
                  type="button"
                  key={ticket.id}
                  className={`ticket ${
                    ticket.id === openTicket ? "selected" : ""
                  } status-${ticket.subStatus}`}
                  onClick={() => {
                    setOpenTicket(ticket.id);
                    setChatOpen(true);
                    const tempUnreadReplies = { ...unreadReplies };
                    delete tempUnreadReplies[ticket.id];
                    setUnreadReplies(tempUnreadReplies);
                  }}
                >
                  <div className="circle">
                    <div className="initials">{initials}</div>
                  </div>
                  {ticket.subStatus === "needsReply" && (
                    <div className="icon">
                      <img
                        height={24}
                        width={24}
                        alt=""
                        src={`${process.env.staticUrl}/admin-icon-chat-waiting.svg`}
                      />
                    </div>
                  )}
                  {ticket.subStatus === "waiting" && (
                    <div className="icon">
                      <img
                        height={24}
                        width={24}
                        alt=""
                        src={`${process.env.staticUrl}/admin-icon-chat-replied.svg`}
                      />
                    </div>
                  )}
                </button>
              );
            })}
          </div>
        </div>
        <style jsx>{styles}</style>
      </div>
    </ThemeContext.Provider>
  );
};

export default SupportSidebar;
