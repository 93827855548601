import React, { RefObject, ChangeEvent, useContext } from "react";
import css from "styled-jsx/css";
import IconButton from "./iconButton";
import stylingConfig from "../styling/config";
import { ThemeContext } from "../lib/contexts";

const styles = css`
  .wrapper {
    position: relative;
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  .icon {
    width: 30px;
    margin-right: ${stylingConfig.grid.xs}px;
  }
  input,
  textarea {
    width: 100%;
    min-height: 40px;
    font-size: 14px;
    padding: 6px 10px;
    outline: none;
    border: none;
    border-radius: 6px;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    font-family: WaggelNunito, Helvetica, Arial, sans-serif !important;
    background-image: none !important;
    background: ${stylingConfig.colours.paleGreyTwo};
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
  }
  .wrapper.customerService input,
  .wrapper.customerService textarea {
    border-radius: 6px 6px 0 0;
    border-bottom: 2px solid ${stylingConfig.colours.paleGrey};
    color: ${stylingConfig.colours.steel};
  }
  .wrapper.analytics input,
  .wrapper.analytics textarea {
    background-color: ${stylingConfig.colours.bluishBlack};
    color: ${stylingConfig.colours.kodaWhite};
    border-radius: 20px;
    font-size: 16px;
    padding: 6px 16px;
  }
  .wrapper.supportChat input,
  .wrapper.supportChat textarea {
    border-radius: 2px;
    font-size: 16px;
  }
  input:focus,
  textarea:focus {
    background: ${stylingConfig.colours.paleGrey};
  }
  textarea {
    resize: vertical;
    padding: 20px;
    min-height: 60px;
    box-sizing: border-box;
  }
  .wrapper {
    border-radius: 6px;
  }
  .hasSubmitButton input {
    padding-right: 50px;
  }
  label {
    color: ${stylingConfig.colours.steel};
    z-index: 0;
    margin-top: 2px;
    margin-bottom: 2px;
    display: block;
    font-weight: 400;
    pointer-events: none;
    padding-left: ${stylingConfig.grid.m}px;
    position: absolute;
    top: -28px;
    font-size: 14px;
    left: -13px;
  }
  label.inInput {
    font-size: 14px;
    top: 9px;
    left: -15px;
  }
  label.textarea.inInput {
    left: -6px;
    top: 18px;
  }
  .analytics label.inInput {
    top: 7px;
    left: -11px;
    font-size: 16px;
    color: ${stylingConfig.colours.otherGrey};
  }
  .wrapper.hasValue label.inInput {
    visibility: hidden;
  }
  .prefixText {
    font-size: 18px;
    z-index: 0;
    margin-bottom: 2px;
    display: block;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
    pointer-events: none;
    padding-left: 10px;
    position: absolute;
    font-weight: 800;
  }
  .postfixText {
    font-size: 14px;
    z-index: 0;
    pointer-events: none;
    font-weight: 800;
    position: absolute;
    right: 24px;
  }
  .postfixText.withSubmit {
    right: 55px;
  }

  .wrapper.hasIcon label {
    left: -28px;
  }

  .wrapper.disabled input,
  .wrapper.disabled textarea {
    background-color: ${stylingConfig.colours.paleGrey};
    opacity: 0.5;
    cursor: default;
  }
  .wrapper.customerService.disabled input,
  .wrapper.customerService.disabled textarea {
    background: none;
    opacity: 1;
  }
  .wrapper.error input {
    background-color: ${stylingConfig.colours.cosmosPink};
  }
  .validationError {
    color: ${stylingConfig.colours.mandyPink};
    font-weight: 700;
    font-size: 14px;
    display: block;
    margin-top: 5px;
  }
  .wrapper.hasIcon + .validationError {
    margin-left: 60px;
  }
  @media screen and (max-width: ${stylingConfig.breakpoints.m}) {
    .icon {
      display: none;
    }
    .wrapper.hasIcon label {
      left: 0;
    }
    .wrapper.hasIcon + .validationError {
      margin-left: 14px;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  .left input {
    text-align: left;
  }
  .center input {
    text-align: center;
  }
  .right input {
    text-align: right;
  }
`;

const extraPadding = css.resolve`
  input {
    padding-left: 25px;
  }
`;

const submitButtonStyles = css.resolve`
  button.submitButton {
    background-color: ${stylingConfig.colours.weirdGreen};
    width: 40px;
    height: 40px;
    border-radius: 20px;
    position: absolute;
    padding-top: 3px;
    padding-left: 4px;
    right: 5px;
  }
  button.submitButton.disable {
    background-color: ${stylingConfig.colours.paleGrey};
    cursor: default;
  }
`;

/* eslint-disable jsx-a11y/label-has-for */
const Input = ({
  label,
  name,
  min,
  max,
  value,
  handleChange,
  autocomplete = "on",
  type = "text",
  error,
  touched = false,
  style,
  icon = "",
  showSubmitButton = false,
  disableSubmitButton = false,
  onSubmit,
  prefixText = "",
  postfixText = "",
  disabled = false,
  labelInInput = true,
  reference,
  align = "left",
  rows = 3
}: {
  label?: string;
  name: string;
  min?: string;
  max?: string;
  value?: string | number;
  handleChange: (e: ChangeEvent<any>) => void;
  autocomplete?: false | string;
  type?: string;
  error?: string;
  touched?: boolean;
  style?: object;
  icon?: string;
  showSubmitButton?: boolean;
  disableSubmitButton?: boolean;
  onSubmit?: () => {};
  prefixText?: string;
  postfixText?: string;
  disabled?: boolean;
  labelInInput?: boolean;
  reference?: RefObject<HTMLTextAreaElement> & RefObject<HTMLInputElement>;
  align?: "left" | "center" | "right";
  rows?: number;
}) => {
  const theme = useContext(ThemeContext);

  return (
    <div>
      <div
        className={`wrapper ${theme} ${value ? "hasValue" : ""} ${
          touched && error ? "error" : ""
        } ${icon !== "" ? "hasIcon" : ""} ${
          showSubmitButton ? "hasSubmitButton" : ""
        } ${disabled ? "disabled" : ""} ${align}`}
      >
        {icon !== "" && <img alt="" className="icon" src={icon} />}
        {prefixText ? <span className="prefixText">{prefixText}</span> : null}
        {postfixText ? (
          <span
            className={`postfixText ${showSubmitButton ? "withSubmit" : ""}`}
          >
            {postfixText}
          </span>
        ) : null}
        {label ? (
          <label
            htmlFor={name}
            className={`${labelInInput ? "inInput" : ""} ${type}`}
          >
            {label}
          </label>
        ) : null}
        {type === "textarea" ? (
          <textarea
            ref={reference}
            className={prefixText ? extraPadding.className : ""}
            name={name}
            onChange={handleChange}
            autoComplete={autocomplete || "off"}
            value={value}
            style={style}
            rows={rows}
            disabled={disabled}
          />
        ) : (
          <input
            ref={reference}
            className={prefixText ? extraPadding.className : ""}
            name={name}
            onChange={handleChange}
            autoComplete={autocomplete || "off"}
            type={type}
            value={value}
            min={min}
            max={max}
            style={style}
            disabled={disabled}
          />
        )}
        {showSubmitButton && (
          <IconButton
            className={`submitButton ${submitButtonStyles.className} ${
              disableSubmitButton ? "disable" : ""
            }`}
            src={
              disableSubmitButton
                ? `${process.env.staticUrl}/chevron-right.svg`
                : `${process.env.staticUrl}/chevron-right-dark-blue.svg`
            }
            width={11}
            height={18}
            onClick={onSubmit}
          />
        )}
      </div>

      {touched && error && <span className="validationError">{error}</span>}
      <style jsx>{styles}</style>
      {submitButtonStyles.styles}
      {extraPadding.styles}
    </div>
  );
};
/* eslint-enable jsx-a11y/label-has-for */

export default Input;
