import React, { useContext, useState } from "react";
import css from "styled-jsx/css";
import stylingConfig from "../../styling/config";
import { ThemeContext } from "../../lib/contexts";

const styles = css`
  .wrapper {
    position: relative;
    width: 100%;
    border-radius: 0;
  }
  .labelWrapper {
    height: 16px;
    margin-bottom: 5px;
  }
  label {
    display: block;
    font-weight: 400;
    pointer-events: none;
    padding-left: 0;
    font-size: 13px;
    line-height: 16px;
    transform: translateY(9px);
    opacity: 0;
    transition: all ease 0.3s;
  }
  .focused label,
  .error label,
  .hasValue label,
  label.textarea {
    opacity: 1;
    transform: translateY(0px);
  }
  .inputWrapper {
    border: 2px solid ${stylingConfig.colours.pennyBlack};
    padding: 18px;
    display: flex;
    background-color: ${stylingConfig.colours.koda};
  }
  .focused .inputWrapper {
    border: 2px solid ${stylingConfig.colours.shuttleGrey};
  }
  .error .inputWrapper {
    border: 2px solid ${stylingConfig.colours.error};
  }
  .disabled .inputWrapper {
    border: 2px solid ${stylingConfig.colours.darkGrey};
  }
  .prefix {
    font-weight: 700;
    line-height: 24px;
    margin-right: 5px;
  }
  input,
  textarea {
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    padding: 0;
    outline: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    background-image: none !important;
    font-family: WaggelSofia, Helvetica, Arial, sans-serif;
    border: 0;

    line-height: 22px;
  }
  ::-webkit-input-placeholder {
    color: ${stylingConfig.colours.pennyBlack};
    font-weight: 400;
    font-size: 16px;
  }
  ::-moz-placeholder {
    color: ${stylingConfig.colours.pennyBlack};
    font-weight: 400;
    font-size: 16px;
  }
  :-moz-placeholder {
    color: ${stylingConfig.colours.pennyBlack};
    font-weight: 400;
    font-size: 16px;
  }
  .hasValue input {
    font-weight: 700;
  }
  textarea {
    resize: none;
  }
  .disabled input,
  .disabled textarea,
  .disabled input:hover,
  .disabled input:focus,
  .disabled textarea:hover,
  .disabled textarea:focus {
    color: ${stylingConfig.colours.darkGrey};
    cursor: default;
    background: none;
  }
  .validationError {
    font-size: 13px;
    line-height: 16px;
    display: block;
    margin-top: 5px;
    font-weight: 400;
    color: ${stylingConfig.colours.error};
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`;

/* eslint-disable jsx-a11y/label-has-for */
const FormInput = ({
  label = undefined,
  name,
  min = undefined,
  max = undefined,
  value,
  handleChange,
  handleBlur = () => {},
  autocomplete = "on",
  type = "text",
  error = undefined,
  touched = false,
  disabled = false,
  reference = undefined,
  testAttr = undefined,
  inputMode = "text",
  minHeight = 64,
  onClick = () => {},
  prefix
}: {
  label?: string;
  name: string;
  autocomplete?: false | string;
  min?: string;
  max?: string;
  value?: string | number;
  handleChange: (e: any) => void;
  handleBlur?: () => void;
  type?: string;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
  reference?: any;
  testAttr?: string;
  inputMode?:
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search";
  minHeight?: number;
  onClick?: () => void;
  prefix?: string;
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <div>
      <div
        className={`wrapper ${value ? "hasValue" : ""} ${
          touched && error ? "error" : ""
        } ${disabled ? "disabled" : ""} ${focused ? "focused" : ""}`}
      >
        <div className="labelWrapper">
          <label htmlFor={name}>{label}</label>
        </div>
        <div className="inputWrapper">
          {type === "textarea" ? (
            <textarea
              data-test={testAttr}
              ref={reference}
              name={name}
              onChange={handleChange}
              autoComplete={autocomplete || "off"}
              value={value}
              style={{ minHeight }}
              rows={3}
              disabled={disabled}
              onBlur={() => {
                setFocused(false);
                handleBlur();
              }}
              onFocus={() => {
                setFocused(true);
              }}
              onClick={onClick}
              placeholder={(touched && error) || focused || value ? "" : label}
            />
          ) : (
            <>
              {prefix && <span className="prefix">{prefix}</span>}
              <input
                inputMode={inputMode}
                data-test={testAttr}
                ref={reference}
                name={name}
                onChange={handleChange}
                autoComplete={autocomplete || "off"}
                type={type}
                value={value}
                min={min}
                max={max}
                disabled={disabled}
                onBlur={() => {
                  setFocused(false);
                  handleBlur();
                }}
                onFocus={() => {
                  setFocused(true);
                }}
                placeholder={
                  (touched && error) || focused || value ? "" : label
                }
                onClick={onClick}
              />
            </>
          )}
        </div>
      </div>

      {touched && error && <span className="validationError">{error}</span>}
      <style jsx>{styles}</style>
    </div>
  );
};
/* eslint-enable jsx-a11y/label-has-for */

export default FormInput;
