import React, { useEffect, useState, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import css from "styled-jsx/css";
import Button from "./button";
import Input from "./input";
import stylingConfig from "../styling/config";
import { useTwilio } from "../lib/twilio";
import { SET_ON_CALL, GET_ON_CALL } from "../containers/supportSidebar";
import { useShowSupport } from "../lib/hooks";
import {
  GetOnCallQuery,
  SetNewOnCallMutation,
  SetNewOnCallMutationVariables
} from "../types/graphql";

const styles = css`
  .phoneDialler {
    position: absolute;
    z-index: 20;
    width: 300px;
    border: solid 2px ${stylingConfig.colours.waggelBlue};
    background: ${stylingConfig.colours.koda};
    left: -130px;
    top: 55px;
    border-radius: 2px;
  }

  .phoneDialler:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: ${stylingConfig.colours.waggelBlue};
    border-width: 13px;
    margin-left: -13px;
  }
  .phoneDialler:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: ${stylingConfig.colours.koda};
    border-width: 10px;
    margin-left: -10px;
  }
  .dialPad {
    padding: 15px;
  }

  .callBtn {
    margin: 5px 15px 15px;
  }
`;

const PhoneDialler = () => {
  const [_answerCall, endCall, makeCall, _, sendDigit] = useTwilio();
  const [onCallMutation] =
    useMutation<SetNewOnCallMutation, SetNewOnCallMutationVariables>(
      SET_ON_CALL
    );

  const [, setShowSupport] = useShowSupport();

  const setOnCall = (newVal: string) => {
    onCallMutation({
      variables: {
        onCall: newVal
      }
    });
  };

  const onCallQuery = useQuery<GetOnCallQuery>(GET_ON_CALL);
  let onCall = "";
  if (onCallQuery.data) {
    ({ onCall } = onCallQuery.data);
  }

  const [phoneNumber, setPhoneNumber] = useState("");
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <div className="phoneDialler">
      <div className="dialPad">
        <Input
          reference={inputRef}
          label="Phone number"
          name="number"
          value={phoneNumber}
          align="left"
          handleChange={(e) => {
            setPhoneNumber(e.target.value);
            if (onCall) {
              sendDigit(e.target.value.slice(-1));
            }
          }}
        />
      </div>
      {phoneNumber && (
        <div className="callBtn">
          {!onCall && (
            <Button
              fullWidth
              ariaLabel="Call"
              theme="green"
              onClick={() => {
                setShowSupport(true);
                setOnCall(phoneNumber);
                makeCall(`phoneNumber:${phoneNumber}`);
              }}
            >
              Call
            </Button>
          )}
          {onCall && (
            <Button
              fullWidth
              ariaLabel="End call"
              theme="red"
              onClick={() => {
                endCall();
                setOnCall("");
              }}
            >
              End call
            </Button>
          )}
        </div>
      )}
      <style jsx>{styles}</style>
    </div>
  );
};

export default PhoneDialler;
