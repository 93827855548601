/* eslint-disable camelcase */
import React, { useState, useEffect, useRef, Fragment } from "react";
import css from "styled-jsx/css";
import {
  gql,
  useQuery,
  useMutation,
  QueryResult,
  MutationFunctionOptions
} from "@apollo/client";

import { differenceInCalendarDays, format } from "date-fns";

import { sortObjectListByKey } from "../../lib/tsutils";
import stylingConfig from "../../styling/config";

import ChatMessage from "../../components/chatMessage";
import IconButton from "../../components/iconButton";
import Button from "../../components/button";
import Loader from "../../components/loader";
import {
  GetNameSelfQuery,
  GetSupportTicketDetailsQuery,
  GetSupportTicketDetailsQueryVariables,
  ReopenTicketMutation,
  ReopenTicketMutationVariables,
  ReplyToChatMutation,
  ReplyToChatMutationVariables,
  SupportTicket,
  SupportTicketReply,
  TypingInTicketMutation,
  TypingInTicketMutationVariables
} from "../../types/graphql";

const GET_NAME_SELF = gql`
  query getNameSelf {
    getSelf {
      id
      firstName
      lastName
    }
  }
`;

const REPLY_TO_CHAT = gql`
  mutation replyToChat($ticketId: ID!, $text: String!) {
    addToSupportTicket(ticketId: $ticketId, medium: "chat", text: $text) {
      id
      ticketId
      medium
      text
      isCustomer
      addedAt
      hidden
      userId
      user {
        id
        firstName
      }
      intentId
      intentConfidence
      intentTrained
    }
  }
`;

const TYPING_IN_TICKET = gql`
  mutation typingInTicket(
    $ticketId: ID!
    $typing: Boolean!
    $userName: String!
  ) {
    typingInSupportTicket(
      ticketId: $ticketId
      typing: $typing
      userName: $userName
    ) {
      success
    }
  }
`;

const styles = css`
  .chat {
    width: 400px;
    background-color: ${stylingConfig.colours.koda};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .messageInnerContainer {
    height: 100%;
    flex-shrink: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .messageInnerContainer .messages {
    overflow-anchor: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .messageInnerContainer #anchor {
    overflow-anchor: auto;
    height: 1px;
  }
  .newDay {
    border-top: solid 1px ${stylingConfig.colours.bluishGrey};
    padding-top: 10px;
    font-size: 12px;
    color: ${stylingConfig.colours.bluishBlack};
    text-align: center;
  }

  .inputWrapper {
    width: 100%;
    padding: 14px 14px;
    border-top: 1px solid ${stylingConfig.colours.bluishGrey};
  }
  .textInput {
    flex-shrink: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .input {
    flex-shrink: 1;
    width: calc(100% - 50px);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${stylingConfig.colours.softGrey};
    border-radius: 2px;
  }
  .input .placeholder {
    pointer-events: none;
    position: absolute;
    top: 14px;
    left: 14px;
    color: ${stylingConfig.colours.bluishSteel};
  }
  .input textarea {
    width: 100%;
    overflow: hidden;
    position: absolute;
    height: 100%;

    /* min-height: 40px; */
    font-size: 14px;
    outline: none;
    border: none;
    -webkit-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    font-family: WaggelNunito, Helvetica, Arial, sans-serif !important;
    background-image: none !important;
    color: ${stylingConfig.colours.bluishSteel};
    background: transparent;
    resize: none;
    padding: 14px 0 14px 14px;
    margin: 0;
  }
  .input textarea,
  .input .textareaCopy {
    font-size: 16px;
    word-wrap: break-word; /* make sure the div and the textarea wrap words in the same way */
    box-sizing: border-box;
    width: calc(100% - 16px);
  }
  .input .textareaCopy {
    min-height: 26px;
    visibility: hidden;
    width: 100%;
    margin: 12px;
    padding: 2px;
  }
  .submitButton {
    flex-shrink: 0;
    align-self: flex-end;
    width: 56px;
    height: 40px;
    padding-left: 14px;
    position: relative;
  }
`;

const SupportChat = ({
  openTicket,
  openTicketDetailsQuery,
  currentlyOpenTicket,
  otherTyping,
  otherOnline,
  reopenTicketMutation
}: {
  openTicket: string;
  openTicketDetailsQuery: QueryResult<
    GetSupportTicketDetailsQuery,
    GetSupportTicketDetailsQueryVariables
  >;
  currentlyOpenTicket: SupportTicket;
  otherTyping: string;
  otherOnline: string;
  reopenTicketMutation: (
    options?: MutationFunctionOptions<
      ReopenTicketMutation,
      ReopenTicketMutationVariables
    >
  ) => Promise<any>;
}) => {
  const [typingInTicketMutation] =
    useMutation<TypingInTicketMutation, TypingInTicketMutationVariables>(
      TYPING_IN_TICKET
    );

  const [chatInput, setChatInput] = useState("");
  const [caretPosition, setCaretPosition] = useState(0);
  const [typing, setTyping] = useState(false);
  const messagesEl = useRef(null);

  useEffect(() => {
    if (chatInput) {
      setTyping(true);
      const timeout = setTimeout(() => setTyping(false), 10000);
      return () => {
        clearTimeout(timeout);
      };
    }
    setTyping(false);
    return () => {};
  }, [chatInput]);

  const getNameSelfQuery = useQuery<GetNameSelfQuery>(GET_NAME_SELF);
  let userName = "CSA";
  if (getNameSelfQuery.data && getNameSelfQuery.data.getSelf) {
    userName = `${getNameSelfQuery.data.getSelf.firstName}`;
  }
  useEffect(() => {
    if (openTicket) {
      typingInTicketMutation({
        variables: {
          ticketId: openTicket,
          typing,
          userName
        }
      });
    }
  }, [openTicket, typing, typingInTicketMutation, userName]);

  const [replyToChatMutation] = useMutation<
    ReplyToChatMutation,
    ReplyToChatMutationVariables
  >(REPLY_TO_CHAT, {
    variables: {
      ticketId: openTicket,
      text: chatInput
    },
    refetchQueries: ["getAllOpenSupportTickets"],
    optimisticResponse: (vars) => ({
      addToSupportTicket: {
        __typename: "SupportTicketReply",
        id: "new",
        ticketId: parseInt(vars.ticketId, 10),
        medium: "chat",
        text: vars.text,
        isCustomer: false,
        addedAt: new Date().toISOString(),
        hidden: false,
        userId: "id",
        user: {
          __typename: "User",
          id: "id",
          firstName: "You"
        },
        intentConfidence: undefined,
        intentId: undefined,
        intentTrained: false
      }
    })
  });

  const [openTicketReplies, setOpenTicketReplies] = useState<
    SupportTicketReply[]
  >([]);

  useEffect(() => {
    if (!openTicketDetailsQuery.loading && !openTicketDetailsQuery.error) {
      if (
        openTicketDetailsQuery.data &&
        openTicketDetailsQuery.data.getSupportTicketById &&
        openTicketDetailsQuery.data.getSupportTicketById.replies
      ) {
        setOpenTicketReplies(
          sortObjectListByKey(
            [...openTicketDetailsQuery.data.getSupportTicketById.replies],
            "addedAt"
          )
        );
      } else {
        setOpenTicketReplies([]);
      }
    }
  }, [
    openTicketDetailsQuery.data,
    openTicketDetailsQuery.error,
    openTicketDetailsQuery.loading
  ]);

  // scroll to bottom when new messages come in
  useEffect(() => {
    if (messagesEl && messagesEl.current) {
      const { current } = messagesEl;
      const scrollHandler = () => {
        const messageInnerContainer = document.getElementById(
          "messageInnerContainer"
        );
        messageInnerContainer.scrollTop = messageInnerContainer.scrollHeight;
      };
      current.addEventListener("DOMNodeInserted", scrollHandler);
      return () => {
        current.removeEventListener("DOMNodeInserted", scrollHandler);
      };
    }
    return () => {};
  }, []);

  // scroll to bottom when opening window initially
  useEffect(() => {
    const messageInnerContainer = document.getElementById(
      "messageInnerContainer"
    );
    if (messageInnerContainer) {
      messageInnerContainer.scroll({
        top: messageInnerContainer.scrollHeight,
        behavior: "smooth"
      });
    }
  }, []);

  const currentTicketLoading =
    !openTicketDetailsQuery.data ||
    (openTicketDetailsQuery.data.getSupportTicketById &&
      `${openTicketDetailsQuery.data.getSupportTicketById.id}` !==
        openTicket) ||
    (openTicketReplies.length > 0 &&
      `${openTicketReplies[0].ticketId}` !== openTicket);

  return (
    <div className="chat">
      <div className="messageInnerContainer" id="messageInnerContainer">
        {currentTicketLoading ? (
          <div className="messages">
            <Loader />
          </div>
        ) : (
          <div className="messages" ref={messagesEl}>
            {openTicketReplies.map((reply, index) => {
              // if (`${reply.ticketId}` !== openTicket) {
              //   return null;
              // }
              return (
                <Fragment key={reply.id}>
                  {index > 1 &&
                    differenceInCalendarDays(
                      reply.addedAt,
                      openTicketReplies[index - 1].addedAt
                    ) > 0 && (
                      <div className="newDay">
                        {differenceInCalendarDays(new Date(), reply.addedAt) > 0
                          ? format(reply.addedAt, "Do MMM YYYY")
                          : "Today"}
                      </div>
                    )}
                  <ChatMessage
                    reply={reply}
                    key={reply.id}
                    disabled={
                      currentlyOpenTicket &&
                      currentlyOpenTicket.status === "closed"
                    }
                    firstName={
                      currentlyOpenTicket && currentlyOpenTicket.tempFirstName
                    }
                  />
                </Fragment>
              );
            })}
            {otherTyping && (
              <ChatMessage
                reply={{
                  __typename: "SupportTicketReply",
                  id: "typing",
                  ticketId: 0,
                  medium: "status",
                  text: `${otherTyping} is typing`,
                  isCustomer: true,
                  addedAt: new Date().toISOString(),
                  hidden: false,
                  userId: undefined,
                  user: undefined,
                  intentConfidence: undefined,
                  intentId: undefined,
                  intentTrained: false,
                  recordingUrl: undefined
                }}
              />
            )}
            {otherOnline === "online" && (
              <ChatMessage
                reply={{
                  __typename: "SupportTicketReply",
                  id: "typing",
                  ticketId: 0,
                  medium: "status",
                  text: `${currentlyOpenTicket.user.firstName} has just come online`,
                  isCustomer: true,
                  addedAt: new Date().toISOString(),
                  hidden: false,
                  userId: undefined,
                  user: undefined,
                  intentConfidence: undefined,
                  intentId: undefined,
                  intentTrained: false,
                  recordingUrl: undefined
                }}
              />
            )}
            {otherOnline === "offline" && (
              <ChatMessage
                reply={{
                  __typename: "SupportTicketReply",
                  id: "typing",
                  ticketId: 0,
                  medium: "status",
                  text: `${currentlyOpenTicket.user.firstName} has just gone offline`,
                  isCustomer: true,
                  addedAt: new Date().toISOString(),
                  hidden: false,
                  userId: undefined,
                  user: undefined,
                  intentConfidence: undefined,
                  intentId: undefined,
                  intentTrained: false,
                  recordingUrl: undefined
                }}
              />
            )}
          </div>
        )}
        <div id="anchor" />
      </div>
      <div className="inputWrapper">
        <div className="textInput">
          {!currentlyOpenTicket || currentlyOpenTicket.status !== "closed" ? (
            <>
              <div className="input">
                {!chatInput && (
                  <div className="placeholder" id="supportChat">
                    Type a message...
                  </div>
                )}

                <textarea
                  title="support chat"
                  id="textchat"
                  name="chatInput"
                  onChange={(e) => {
                    setChatInput(e.target.value);
                    if (e.target.selectionStart !== caretPosition) {
                      setCaretPosition(e.target.selectionStart);
                    }
                  }}
                  value={chatInput}
                  onKeyUp={(e) => {
                    // if you press enter
                    if (e.which === 13 && caretPosition === chatInput.length) {
                      if (chatInput.replace(/\n/g, "")) {
                        replyToChatMutation();
                      }
                      setChatInput("");
                    }
                  }}
                  rows={1}
                />
                <div className="textareaCopy">
                  {chatInput.replace(/\n/g, "<br/>")}
                </div>
              </div>
              <div className="submitButton">
                <IconButton
                  src={`${process.env.staticUrl}/icon-support-send-message.svg`}
                  width={40}
                  height={40}
                  alt="Submit message"
                  onClick={() => {
                    if (chatInput.replace(/\n/g, "")) {
                      replyToChatMutation();
                      setChatInput("");
                    }
                  }}
                />
              </div>
            </>
          ) : (
            <Button
              fullWidth
              onClick={() => {
                reopenTicketMutation({
                  variables: {
                    ticketId: openTicket
                  }
                }).then(() => {
                  openTicketDetailsQuery.refetch();
                });
              }}
            >
              Reopen ticket
            </Button>
          )}
        </div>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default SupportChat;
